import _ from 'lodash';
import React from 'react';

import {
    Box, Flex,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    // PopoverHeader,
    // PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    IconButton,
    Text,
    useDisclosure
} from "@chakra-ui/core";
import { MdSend } from 'react-icons/md';

import AnswerScreen from './AnswerScreen';
import Keyboard from './Keyboard';
import ResultPopover from './ResultPopover';
import firebase from '../firebase';
import {useGameActions, useWaitForPlayers} from '../context';
import {useI18n} from '../i18n';

function checkValidFormat(answer, answerType) {
    answer = answer.toString();
    if (answerType.type === 'word') {
        answer = _.words(answer, /[^, ]+/g);
    }
    return answer.length === answerType.length;
}

function CommsBody({ answerType, answerState, onClose, inputRef }) {
    const {t, flip} = useI18n('comms');
    const { submitAnswer: submit, resetAnswer, resetAnswerCollab, increaseLevel } = useGameActions();
    const [answer, _setAnswer] = React.useState(answerState?.submitted || '');
    const [didSubmit, setDidSubmit] = React.useState(!!answerState?.submitted);

    const setAnswer = (newAnswer) => {
        _setAnswer(newAnswer.toUpperCase());
        setDidSubmit(false);
    }

    const submitAnswer = (e) => {
        e.preventDefault();
        submit(answer.replace(/'/, '')); // ignore apostrophes
        setDidSubmit(true);
    }

    // FIXME: solution is still kind of brittle
    const [_timestamp, _setTimestamp] = React.useState(0);
    const [resetCollabCountdown, _resetAnswerCollab] = useWaitForPlayers('reset-answer-'+_timestamp, resetAnswerCollab);
    const [advanceCollabCountdown, increaseLevelCollab] = useWaitForPlayers('increase-level', increaseLevel, onClose);
    const onResultPopoverClose = () => {
        if (answerState?.isCorrect) {
            // onClose();
            increaseLevelCollab();
        } else {
            setAnswer('');
            resetAnswer();
            // reset locally first, then globally after 15 seconds to give everyone a chance to see
            _resetAnswerCollab();
            // setTimeout(() => {
            //     // eslint-disable-next-line
            //     inputRef.current?.focus();
            // }, 100)
        }
    }
    const countdown = advanceCollabCountdown || resetCollabCountdown;

    React.useEffect(() => {
        // reset state after receiving new data from collab play
        setAnswer(answerState?.submitted || '');
        setDidSubmit(!!answerState?.submitted);
        if (answerState?.timestamp) {
            _setTimestamp(answerState.timestamp);
        }
    }, [answerState]);

    const borderColor = didSubmit && answerState ? (answerState.isCorrect ? 'green.500' : 'red.500') : undefined;

    React.useEffect(() => {
        if (answerState) {
            firebase.analytics().logEvent('submit_answer', { answer: answerState.submitted, is_correct: answerState.isCorrect });
        }
    }, [answerState]);

    return (
        <Box
            h="auto"
            w="200px"
            overflow="hidden"
            display="flex"
            flexDir="column"
            alignItems="center"
        >
            <Flex
                as="form"
                direction="row"
                wrap="nowrap"
                width="100%"
                onSubmit={submitAnswer}
            >
                <AnswerScreen
                    answerType={answerType}
                    value={answer}
                    onChange={setAnswer}
                    ref={inputRef}
                    borderColor={borderColor}
                    isDisabled={didSubmit}
                />
                <ResultPopover isCorrect={answerState?.isCorrect} onClose={onResultPopoverClose} countdown={countdown}>
                    <IconButton
                        variantColor="blue"
                        {...flip({
                            marginLeft: "5px",
                            transform: 'rotate(<<0|180>>deg)' // flip the icon
                        })}
                        aria-label={t('submit')}
                        icon={MdSend}
                        type="submit"
                        isDisabled={!checkValidFormat(answer, answerType) || didSubmit}
                        isLoading={didSubmit && answerState?.isCorrect === undefined}
                        // onClick={submitAnswer}
                    />
                </ResultPopover>
            </Flex>
            {answerState?.isCorrect && countdown && <Text>{t('continuingIn', countdown)}</Text>}
            <Keyboard showLetters />
        </Box>
    );
}

export default function Comms({ answerType, answerState, button }) {
    const inputRef = React.useRef(null);
    const { isOpen, onClose: _onClose, onOpen } = useDisclosure();
    const onClose = () => {
        _onClose();
        // resetAnswer();
    }

    React.useEffect(() => {
        // open the box when it is submitted in collab play
        if (answerState) {
            onOpen();
        }
    }, [answerState, onOpen]);

    return (
        <Popover
            closeOnBlur={false}
            initialFocusRef={inputRef}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
        >
            <PopoverTrigger>
                {button}
            </PopoverTrigger>
            <PopoverContent zIndex={30} w="250px">
                <PopoverArrow />
                <PopoverCloseButton />
                {/* <PopoverHeader>Hints</PopoverHeader> */}
                <PopoverBody>
                    <CommsBody {...{answerType, answerState, onClose, inputRef}} />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
