import React from 'react';
import { Box, Button } from "@chakra-ui/core";
import PageContainer from './PageContainer';
import {useIsCompetitive, useIsCollaborative, useGameContext, useWaitForPlayers} from './context';
import { useI18n } from './i18n';
import styles from './Intro.module.css';
import logo from './logo.png';

import CompetitionIntro from './content/CompetitionIntro';
import StandardIntro from './content/Intro';
import JuniorIntro from './content/JuniorIntro';

export function IntroContent({goodLuck}) {
    const { t } = useI18n('intro');
    const isCompetitive = useIsCompetitive();
    const isCollaborative = useIsCollaborative();
    const { Intro: IntroInnerComponent, id: version, name: gameName } = useGameContext();

    let IntroComponent;
    if (isCompetitive) {
        IntroComponent = CompetitionIntro;
    } else {
        if (version === 'junior') {
            IntroComponent = JuniorIntro;
        } else {
            IntroComponent = StandardIntro;
        }
    }

    return (
        <Box className={styles.introWrapper}>
            <IntroComponent name={gameName} isCollaborative={isCollaborative}>
                <IntroInnerComponent />
            </IntroComponent>
            {goodLuck && <p>{t('goodLuck')}</p>}
        </Box>
    );
}

export default function IntroPage({nextLevel}) {
    const { t, flip } = useI18n('intro');
    const [didSubmit, setDidSubmit] = React.useState(false);
    const [advanceCountdown, advanceCollab] = useWaitForPlayers('next-level', React.useCallback(approved => {
        if (approved) {
            nextLevel();
        }
    }, [nextLevel]), (approved) => {
        if (!approved) setDidSubmit(false);
    });
    const onClick = (approved) => {
        setDidSubmit(true);
        advanceCollab(approved);
    }

    return (
        <PageContainer>
            <Box
                h="70%"
                w="70%"
                overflow="auto"
                border="10px solid black"
                p={5} m={10}
            >
                <Box w="50%" margin="auto"><img src={logo} alt="Bagels and Locks Studios" /></Box>
                <IntroContent goodLuck />
            </Box>
            <Box textAlign="center">
                {advanceCountdown && !didSubmit ? (
                    <Box my={4}>
                        <Button onClick={() => onClick(false)} isDisabled={didSubmit} mx={3} variantColor="darkBrown">
                            {t('notReady')}
                        </Button>
                        <Button dir="ltr" {...flip({rightIcon: 'arrow-<<forward|back>>'})} variantColor="salmon" variant="solid" mx={3} onClick={() => onClick(true)} isDisabled={didSubmit} isLoading={didSubmit && !advanceCountdown} loadingText={t('begin')}>
                            {t('begin')}
                            {advanceCountdown && ` (${advanceCountdown})`}
                        </Button>
                    </Box>
                ) : (
                    <Button dir="ltr" {...flip({rightIcon: 'arrow-<<forward|back>>'})} variantColor="salmon" variant="solid" marginY={4} marginX="auto" onClick={() => onClick(true)} isDisabled={didSubmit} isLoading={didSubmit && !advanceCountdown} loadingText={t('begin')}>
                        {t('begin')}
                        {advanceCountdown && ` (${advanceCountdown})`}
                    </Button>
                )}
            </Box>
        </PageContainer>
    )
}
