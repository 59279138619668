const en = {
    __dir: 'ltr',
    versionSelector: {
        beforeYouBegin: 'Before you begin your quest, please confirm your identity',
        name: 'Name:',
        playerName: 'Player Name:',
        teamName: 'Team Name:',
        game: 'Game:',
        selectGame: 'Select Game',
        mission: 'Mission:',
        players: 'Players:',
        notReady: 'Not ready',
        begin: 'BEGIN YOUR QUEST',
        dontWorryChangeLevel: 'Don’t worry! If you start your game and decide that a different level would be better for you, you can switch to another level by clicking on “Settings” at the bottom of your screen.',
        hoverInfoJunior: '%s% is designed for kids age 9-13, or for novice puzzlers (younger players can complete the game with an adult helper).',
        hoverInfoStandard: '%s% is designed for adults and teens age 14+. For novice puzzlers and experienced puzzlers.',
        hoverInfoPro: '%s% is designed for adults and teens age 15+. For experienced puzzlers.',
        hoverInfoName: 'This is the name that will be displayed on your certificate when you complete your quest.',
        hoverInfoPlayerName: 'Your teammates will be able to identify you by this name.',
    },
    intro: {
        begin: 'Begin Your Quest',
        notReady: 'Not ready',
        goodLuck: 'Now you are ready to get started! Good luck! Click to the button below to get started!',
    },
    footer: {
        currentLocation: 'Current location:',
        clueX: 'Clue %s%',
        labelCommunications: 'Communications',
        labelBackpack: 'Backpack',
        labelNotes: 'Notes',
        labelHint: 'Hint',
        labelSettings: 'Settings',
        leaderboard: 'Leaderboard',
    },
    hints: {
        click: 'Click for a hint',
        penaltyWarning: "Warning: If you click for a hint, you'll get a time penalty.",
        ifNotWorking: "If something isn't working properly, just reload the page. Don't worry, we've saved your progress.",
        instructionsInBackpack: 'Game instructions can be found in the backpack.',
        hintN: 'Hint %s%',
    },
    settings: {
        title: 'Settings',
        sound: 'Sound',
        bgSound: 'Background sound',
        sfx: 'Sound effects',
        volume: 'Volume',
        changeVersion: 'Change version', // label in body
        versionSelect: {
            select: 'Select', // in body
            title: 'Change version', // in popup
            areYouSure: 'Are you sure you want to switch to %s%?',
            cancel: 'Cancel',
            switch: 'Switch',
        }
    },
    notepad: {
        title: 'Notes',
        placeholder: 'Add your notes and thoughts here',
        lockedForOthers: 'Notepad is locked for other players.',
        lockedForMe: 'Someone else is editing the notepad.',
    },
    backpack: {
        title: 'Backpack',
        back: 'back',
        labelInstructions: 'Game Instructions',
        labelCalculator: 'Calculator',
        zoomIn: 'Zoom in',
        zoomOut: 'Zoom out'
    },
    comms: {
        submit: 'Submit',
        success: 'Code processed successfully!',
        invalid: 'Invalid code!',
        continue: 'Continue',
        continuingIn: 'Continuing in %s%',
        useKeyboardMessage: 'Click in the box above and use your keyboard to type your solution',
        badInputLabel: {
            message: 'This input only accepts %s%',
            letter: 'letters',
            number: 'numbers'
        }
    },
    clickToOpen: "Click to open",
    certificate: {
        congratulations: 'Congratulations',
        youCompleted: 'You have successfully completed',
        finalTime: 'Final time: %s%',
        yourImpressiveSkillSet: 'Your impressive skill set shows that you have what it takes to become a member of our elite team.',
        getReady: 'Get ready for your next challenge - we will be contacting you again soon!',
        shareScreenshot: 'Share a screenshot of this and tag us on social media!',
        exit: 'Exit Game',
        seeLeaderboard: 'See Leaderboard',
        checkOutOutOtherGames: 'Check out all of our games!',
    },
    splash: {
        gameFull: 'The game you tried to join is full.',
        trialExpired: 'Your free trial has expired',
        playRest: 'Play the rest of the game',
        backToWebsite: 'Back to Our Website',
        emailUs: 'Email Us',
        welcomeBack: 'Welcome back, %s%',
        play: 'PLAY',
    },
    leaderboard: {
        rank: 'Rank',
        team: 'Team',
        level: 'Level',
        time: 'Time',
        waiting: 'waiting...',
        finished: 'finished',
        intro: 'intro',
    },
    competition: {
        setup: 'Set up competition',
        competitionName: 'Competition name:',
        mission: 'Mission:',
        teams: 'Teams',
        allowModifyName: 'Allow players to modify their team name',
        submit: 'Submit',
        leaderboard: 'Leaderboard',
        newTabWarning: 'To enter the game, click directly on the link for your team (do NOT open in a new tab!):',
    },
}
export default en;
