
export const stateManager = (dataManager, features = {}) => {
    const initialState = dataManager.initialState;
    const { competitive } = features;

    const actions = (state) => {
        if (!state.timer) {
            state.timer = null;
        }
        return {
            increaseLevel() {
                return dataManager.fetchData.increaseLevel().then(({ nextLevel, nextLevelData }) => ({
                    ...state,
                    level: nextLevel,
                    levelData: nextLevelData,
                    timer: nextLevel === 1 ? {
                        ...(state.timer || {}),
                        started: Date.now(),
                        penalties: 0
                    } : state.timer,
                    answer: null,
                    mapData: {
                        exiting: state.levelData?.mapCoords || null,
                        entering: nextLevelData?.mapCoords || null
                    },
                })).catch(e => {
                    // error is most likely forbidden level increase from trying to manually submit
                    console.error(e);
                    return state;
                });
            },
            mapToClue() {
                return { ...state, mapData: null };
            },
            getHint() {
                const penaltyTimes = [3, 6, 12];
                return dataManager.fetchData.getHint().then((hint) => ({
                    ...state,
                    levelData: {
                        ...state.levelData,
                        hints: state.levelData.hints.concat(hint || [])
                    },
                    timer: competitive ? {
                        ...state.timer,
                        penalties: state.timer.penalties + penaltyTimes[state.levelData.hints.length]
                    } : state.timer
                }));
            },
            setNotes(notes) {
                return { ...state, notes };
            },
            submitAnswer(answer) {
                return dataManager.fetchData.submitAnswer(answer).then(({ isCorrect, timestamp }) => ({
                    ...state,
                    answer: {
                        submitted: answer,
                        isCorrect,
                        timestamp
                    },
                    timer: state.level === 10 && isCorrect && state.timer?.started ? {
                        ...state.timer,
                        started: null,
                        elapsed: (state.timer.elapsed || 0) + (Date.now() - state.timer.started),
                        finished: true
                    } : state.timer,
                }));
            },
            resetAnswer() {
                return { ...state, answer: null };
            },
            switchGameVersion(newVersion) {
                return dataManager.fetchData.switchGameVersion(newVersion).then(({ gameObject, levelData }) => ({
                    ...state, gameObject, levelData
                }));
            },
            setName(name) {
                return { ...state, name };
            },
        }
    };

    return { initialState, actions };
};

export const bindActions = (actions, gameState, updateState) => ({
    switchGameVersion: (newVersion) => actions(gameState).switchGameVersion(newVersion).then(updateState),
    setName: (name) => updateState(actions(gameState).setName(name)),
    increaseLevel: () => actions(gameState).increaseLevel().then(updateState),
    mapToClue: () => updateState(actions(gameState).mapToClue()),
    getHint: () => actions(gameState).getHint().then(updateState),
    setNotes: (notes) => updateState(actions(gameState).setNotes(notes)),
    submitAnswer: (answer) => actions(gameState).submitAnswer(answer).then(updateState),
    resetAnswer: () => updateState(actions(gameState).resetAnswer()),
});
