import React from 'react';
import { Box, Image, Button, Text } from '@chakra-ui/core';
import clsx from 'clsx';
import { useGameContext, useGameState } from '../context';
import styles from './PuzzleTransition.module.css';

import blankPuzzlePiece from '../content/jerusalemShared/art/puzzle/blank.jpg';

const ABC = {1: 'A', 2: 'B', 3: 'C', 4: 'D', 5: 'E', 6: 'F', 7: 'G', 8: 'H', 9: 'I'};

// {front: React.Element, back: React.Element, flipped: Boolean, size: {width: String, height: String}}
function Flipper({front, back, flipped, size={}, slow, className, ...props}) {
    return (
        <Box
            className={clsx(styles.flipContainer, {
                [styles.flipperBack]: flipped,
                [styles.flipperFront]: !flipped,
                [styles.flipperSlow]: slow
            }, className)}
            {...props}
            {...size}
        >
            <div className={clsx(styles.flipper)}>
                <Box className={styles.front} {...size}>
                    {front}
                </Box>
                <Box className={styles.back} {...size}>
                    {back}
                </Box>
            </div>
        </Box>
    )
}

function PuzzleGrid({puzzleData, activePiece, level, showFront, onFinish}) {
    const containerRef = React.useRef(null);
    const [pos, setPos] = React.useState(null);
    const [size, setSize] = React.useState({});
    const [flipped, setFlipped] = React.useState(false);

    const onLoad = React.useCallback(() => {
        if (!activePiece) {return}

        let top, left;
        const OFFSET = 5;
        if (activePiece.fromPosition[0] === 't') {
            top = OFFSET;
        } else if (activePiece.fromPosition[0] === 'b') {
            top = window.innerHeight - containerRef.current.offsetHeight - OFFSET;
        }
        if (activePiece.fromPosition[1] === 'l') {
            left = OFFSET;
        } else if (activePiece.fromPosition[1] === 'r') {
            left = window.innerWidth - containerRef.current.offsetWidth - OFFSET;
        }
        setPos({ top, left });

        console.log(containerRef.current);
        let { offsetHeight, offsetWidth, clientHeight, clientWidth, offsetTop, offsetLeft, clientTop, clientLeft } = containerRef.current;
        console.log({ offsetHeight, offsetWidth, clientHeight, clientWidth, offsetTop, offsetLeft, clientTop, clientLeft });

        setSize({
            height: containerRef.current.clientHeight + 'px',
            width: containerRef.current.clientWidth + 'px'
        });

        setTimeout(() => {
            setPos({
                top: (containerRef.current.offsetTop + containerRef.current.clientTop) + 'px',
                left: (containerRef.current.offsetLeft + containerRef.current.clientLeft) + 'px',
                boxShadow: 'lg'
            });
        }, 1000);
        setTimeout(() => {
            setPos(pos => ({ ...pos, boxShadow: null }));
            setFlipped(true);
        }, 4000);
        setTimeout(onFinish, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePiece]);

    return (
        <div className={styles.puzzleGrid}>
            {puzzleData.map((piece, i) => (
                <Box key={piece.cell} className={styles.gridItem} gridArea={ABC[piece.cell]} ref={piece === activePiece ? containerRef : null}>
                    {piece === activePiece ? (
                        <Flipper
                            front={<Image src={piece.front} className={styles.puzzleImage} onLoad={i === puzzleData.length - 1 ? onLoad : null} />}
                            back={<Image src={piece.back} className={styles.puzzleImage} />}
                            flipped={flipped}
                            size={size}
                            className={clsx(pos && styles.moving)}
                            {...(pos || {})}
                        />
                    ) : (
                        <Image
                            src={i < level - 1 || !activePiece ? (showFront ? piece.front : piece.back) : blankPuzzlePiece}
                            className={styles.puzzleImage}
                            onLoad={i === puzzleData.length - 1 ? onLoad : null}
                        />
                    )}
                </Box>
            ))}
        </div>
    )
}

function LocationsTrack({locations, level, shouldMove}) {
    const oldLocationRef = React.useRef(null);
    const newLocationRef = React.useRef(null);
    const [pos, setPos] = React.useState({display: 'none'});

    React.useEffect(() => {
        if (oldLocationRef.current && newLocationRef.current) {
            setPos({
                top: oldLocationRef.current.offsetTop + 'px',
                left: oldLocationRef.current.offsetLeft + 'px'
            });
            if (shouldMove) {
                setTimeout(() => {
                    setPos({
                        top: newLocationRef.current.offsetTop + 'px',
                        left: newLocationRef.current.offsetLeft + 'px'
                    });
                }, 1000);
            }
        }
    }, [level, shouldMove]);

    return (
        <div>
            <Box className={styles.locationsTrackMovingBubble} {...pos} />
            <div className={styles.locationsTrackContainer}>
                {locations.map((place, i) => (<React.Fragment key={i}>
                    <div className={styles.locationsTrackBubble} ref={i === level - 2 ? oldLocationRef : i === level - 1 ? newLocationRef : null}></div>
                    {i < locations.length-1 && <div className={styles.locationsTrackConnector}></div>}
                </React.Fragment>))}
            </div>
            <div className={styles.locationsTrackLabels}>
                {locations.map((place, i) => (
                    <Text key={i} className={clsx(styles.locationsTrackLabel, i === level - 1 && styles.newLocation)}>{place}</Text>
                ))}
            </div>
        </div>
    )
}

function PuzzleTransition({ data, enterClue }) {
    const { map: {puzzleData, locations} } = useGameContext();
    let { level } = useGameState();
    if (level === 'TRIAL_EXPIRED') {
        level = 2; // just over here, to show the correct transition
    }
    const activePiece = puzzleData[level - 2]; // first shown when entering level 2

    const [moveDot, setMoveDot] = React.useState(false);
    const [flipped, setFlipped] = React.useState(level === 'CERTIFICATE');
    const onFinish = React.useCallback(() => {
        if (level === 10 && !flipped) {
            setTimeout(() => {
                setFlipped(true);
                setTimeout(() => {
                    setMoveDot(true);
                    setTimeout(enterClue, 5000);
                }, 3000);
            }, 2000);
        } else {
            setMoveDot(true);
            setTimeout(enterClue, 5000);
        }
    }, [enterClue, flipped, level]);

    React.useEffect(() => {
        if (!activePiece && level !== 'CERTIFICATE') {
            enterClue();
        }
        if (level === 'CERTIFICATE') {
            setTimeout(() => {
                setFlipped(false);
                setTimeout(enterClue, 4000);
            }, 2000);
        }
    }, [activePiece, enterClue, level]);

    if (!activePiece && level !== 'CERTIFICATE') {
        return null;
    }

    return (
        <Box h="100vh" display="flex" flexDirection="column" backgroundColor="#f3f3f3">
            <Flipper
                front={<PuzzleGrid puzzleData={puzzleData} activePiece={activePiece} level={level} onFinish={onFinish} />}
                back={(level === 10 || level === 'CERTIFICATE') && <PuzzleGrid puzzleData={puzzleData} activePiece={null} level={10} showFront={true} onFinish={onFinish} />}
                flipped={flipped}
                size={{ maxHeight: 'calc(100vh - 100px)', height: '100%', width: '100%' }}
                slow
            />
            <LocationsTrack locations={locations} level={level} shouldMove={moveDot} />
        </Box>
    )
}

export default PuzzleTransition;
