import React from 'react';
import _ from 'lodash';
import {
    Box, Flex,
    Text,
    Select, Button,
    FormLabel, Switch,
    useDisclosure,

    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,

    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,

    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverHeader,
    // PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/core";
import {FaVolumeDown, FaVolumeUp} from 'react-icons/fa';
import { useGameSettings, useGameContext, useGlobalContext, useIsCompetitive, useCollaborativeState, useWaitForPlayers } from './context';
import { useI18n } from './i18n';

function SelectVersion({versions, selected, onChange}) {
    const {t} = useI18n('settings.versionSelect');
    const [_selected, _setSelected] = React.useState(selected);
    const cancelRef = React.useRef(null);

    const [didSubmit, setDidSubmit] = React.useState(false);
    const [switchTo, setSwitchTo] = useCollaborativeState('version-switch-to', null);
    const [submitCountdown, _submit] = useWaitForPlayers('version-switch', React.useCallback((approved) => {
        if (approved && switchTo) {
            onChange(switchTo);
        }
        setSwitchTo(null);
    }, [onChange, setSwitchTo, switchTo]), () => setDidSubmit(false));
    const submit = approve => {
        _submit(approve);
        setDidSubmit(true);
    }

    return (<>
        <Flex direction="row">
            <Select value={_selected} onChange={e => {_setSelected(e.target.value)}}>
                {_.map(versions, (version, key) => (
                    <option key={key} value={key}>{version}</option>
                ))}
            </Select>
            <Button onClick={() => setSwitchTo(_selected)}>{t('select')}</Button>
        </Flex>

        <AlertDialog
            isOpen={!!switchTo}
            leastDestructiveRef={cancelRef}
            onClose={() => submit(false)}
            closeOnOverlayClick={false}
            closeOnEsc={false}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    {t('title')}
                </AlertDialogHeader>

                <AlertDialogBody>
                    {t('areYouSure', versions[switchTo])}
                </AlertDialogBody>

                <AlertDialogFooter>
                    <Button ref={cancelRef} isDisabled={didSubmit} onClick={() => submit(false)}>
                        {t('cancel')}
                    </Button>
                    <Button variantColor="blue" isDisabled={didSubmit} onClick={() => submit(true)} ml={3}>
                        {t('switch')}
                        {submitCountdown && ` (${submitCountdown})`}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    </>)
}

function SettingsBody({actions}) {
    const gameSettings = useGameSettings();
    const gameContext = useGameContext();
    const { versions } = useGlobalContext();
    const isCompetitive = useIsCompetitive();
    const { t, flip } = useI18n('settings');

    return (
        <Box>
            <Box>
                <Text as="h3" fontSize="xl">{t('sound')}</Text>
                <Flex align="center">
                    <FormLabel htmlFor="settings-sound-bg" {...flip({paddingRight: '12px', paddingLeft: 0})}>{t('bgSound')}</FormLabel>
                    <Switch dir="ltr" id="settings-sound-bg" isChecked={gameSettings.sound.bg} onChange={actions.settings.toggleBg} />
                </Flex>
                <Flex align="center">
                    <FormLabel htmlFor="settings-sound-fx" {...flip({paddingRight: '12px', paddingLeft: 0})}>{t('sfx')}</FormLabel>
                    <Switch dir="ltr" id="settings-sound-fx" isChecked={gameSettings.sound.fx} onChange={actions.settings.toggleFx} />
                </Flex>
                <Flex direction="column">
                    <FormLabel htmlFor="settings-sound-volume" {...flip({paddingRight: '12px', paddingLeft: 0})}>{t('volume')}</FormLabel>
                    <Flex direction="row" alignItems="center" dir="ltr">
                        <Box as={FaVolumeDown} mr={2} />
                        <Slider
                            id="settings-sound-volume"
                            value={gameSettings.sound.volume}
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={v=>actions.settings.setVolume(v)}
                        >
                            <SliderTrack />
                            <SliderFilledTrack />
                            <SliderThumb bg="salmon.500" />
                        </Slider>
                        <Box as={FaVolumeUp} ml={2} />
                    </Flex>
                </Flex>
            </Box>
            {!isCompetitive && <Box>
                <Text as="h3" fontSize="xl">{t('changeVersion')}</Text>
                <SelectVersion versions={versions} selected={_.findKey(versions, v => v===gameContext.name)} onChange={actions.switchGameVersion} />
            </Box>}
        </Box>
    )
}

function GameSettings({actions, button}) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t, rtlClass, flip } = useI18n();

    const [switchTo] = useCollaborativeState('version-switch-to', null);
    React.useEffect(() => {
        if (switchTo) {
            onOpen();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [switchTo]);
    // open when another player wants to change version

    return (
        <Popover
            closeOnBlur={false}
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
        >
            <PopoverTrigger>
                {button}
            </PopoverTrigger>
            <PopoverContent zIndex={30} w="500px" className={rtlClass}>
                <PopoverArrow />
                <PopoverCloseButton {...flip({left: 'auto', right: 2})} />
                <PopoverHeader>{t('settings.title')}</PopoverHeader>
                <PopoverBody>
                    <SettingsBody actions={actions} />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default GameSettings;
