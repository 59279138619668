import React from 'react';
import { Button, Text, SimpleGrid, Box, Tooltip, useDisclosure } from '@chakra-ui/core';
import { useI18n } from '../i18n';

function KeypadButton({number, letters, showLetters}) {
    return (
        <Button
            isDisabled
            w={50}
            h={50}
            display="flex"
            flexDir="column"
            variantColor="darkBrown"
        >
            <Text>{number}</Text>
            {showLetters && <Text>{letters}</Text>}
        </Button>
    );
}

export default function Keyboard({showLetters}) {
    const gridRef = React.useRef(null);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const {t} = useI18n('comms');

    React.useLayoutEffect(() => {
        setWidth(gridRef.current.clientWidth);
        setHeight(gridRef.current.clientHeight);
    }, [gridRef]);

    const {isOpen, onClose, onToggle} = useDisclosure();

    return (
        <SimpleGrid dir="ltr" columns={3} spacing={2} position="relative" ref={gridRef}>
            <Tooltip isOpen={isOpen} label={t('useKeyboardMessage')} placement="top" zIndex={50}>
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    w={width}
                    h={height}
                    zIndex={31}
                    onClick={onToggle}
                    onMouseLeave={onClose}
                />
            </Tooltip>
            <KeypadButton showLetters={showLetters} number="1" letters="" />
            <KeypadButton showLetters={showLetters} number="2" letters="ABC" />
            <KeypadButton showLetters={showLetters} number="3" letters="DEF" />
            <KeypadButton showLetters={showLetters} number="4" letters="GHI" />
            <KeypadButton showLetters={showLetters} number="5" letters="JKL" />
            <KeypadButton showLetters={showLetters} number="6" letters="MNO" />
            <KeypadButton showLetters={showLetters} number="7" letters="PQRS" />
            <KeypadButton showLetters={showLetters} number="8" letters="TUV" />
            <KeypadButton showLetters={showLetters} number="9" letters="WXYZ" />
            <KeypadButton showLetters={showLetters} number="" letters="" />
            <KeypadButton showLetters={showLetters} number="0" letters="" />
            <KeypadButton showLetters={showLetters} number="" letters="" />
        </SimpleGrid>
    )
}
