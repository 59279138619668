import React from 'react';
import { useI18n } from '../i18n';

function IntroEnglish({name, isCollaborative, children}) {
    return <div>
        <p>Welcome to {name}! Before you get started, let’s get you oriented. </p>
        {isCollaborative ? (
            <p>The link you have received allows up to 4 different devices to connect to the same game. Simply send it to the people you’ll be playing with so everyone can connect at the same time! If you are playing as a group, you can use the notepad within the game to pass notes among all the players. Some group play users like to also connect using video meeting software outside the game to make it easier to chat with all the players; that’s fine, as long as you do not rebroadcast or redistribute this game in any way.</p>
        ) : (
            <p>The link you have received works for ONE device only. You can play with as many people as you want, as long as you are all together around one device. <strong>You may not rebroadcast or redistribute this game in any way (this includes remotely connecting other people to the game via Zoom, Google Meet, or other programs or apps).</strong></p>
        )}
        <p><strong>If you have trouble connecting, please contact help@bagelsandlocks.com</strong></p>
        {children}
        <p>Don’t get discouraged if you don’t know what to do immediately. Sometimes the best thing to do is read your clue over again, carefully, paying extra careful attention to the wording. In most cases, you will not be able to solve the puzzles just by reading them - so we’re providing you with some resources. Throughout your quest, you will have access to a “Backpack”, that contains information that may help you. You can access these resources by clicking on the briefcase icon at the bottom of the screen. </p>
        <p>If you do get stuck and feel like you need a hint, click on the ? icon at the bottom of the screen for a hint. You will be able to ask for up to 3 hints at each station. Keep in mind that for each station, Hint #3 is designed to basically give you the answer to the puzzle, so use it only if you’re really stuck! </p>
        <p>If you believe you have the solution to your puzzle, you can enter it into your communication device by clicking on the cellphone icon at the bottom of your screen. Using the keyboard on your own device (i.e. your computer), enter your solution (letters or numbers). If your answer is correct, you’ll advance to the next clue. If it’s not, you can try again! There is no limit to the number of times you can try to enter a solution.</p>
        <p>If at any point during your game you decide that a different level would be better for you, you can switch to another level by clicking on “Settings” at the bottom of your screen. This is also where you can adjust the game sounds.</p>
        <p>Note: this game is designed to take a fair amount of time to complete - some people will complete it faster than others will. And that’s just fine! If you find you need to take a break, you can simply log off and come back later to continue. We’ll save your place for you!</p>
        <p><strong>Please don’t send your login information to anyone else. Your login information is for your game ONLY.</strong> You can recommend the game to your friends on social media using the share button at the end of the game.</p>
    </div>
}

export function IntroHebrew({ name, isCollaborative, children }) {
    return <div>
        <p>ברוכים הבאים ל-{name}! לפני התחלת המשחק, נדגיש כמה דברים חשובים.</p>
        {isCollaborative ? (<>
            <p>הקישור שקיבלתם מאפשר להתחבר עד ארבעה מכשירים שונים (תלוי במה בחרתם בעת הרכישה) לאותו משחק. <strong>כיצד?</strong> שלחו את הקישור לשחקנים האחרים, למחשב שלהם, כשאתם מוכנים להתחיל.</p>
            <p>בזמן משחק קבוצתי, תוכלו להשתמש במחברת הנמצאת בתוך המשחק כדי להעביר תווים בין כל השחקנים. בזמן משחק קבוצתי יש שמעדיפים להתחבר גם מחוץ למשחק כדי לאפשר בצ'ט לכל המשתתפים לדבר ביניהם - וזה עובד מעולה, כל עוד לא משדרים או מפיצים את המשחק הזה בשום צורה שהיא.</p>
            <p><strong>אין רשות לשדר או להפיץ את המשחק הזה בשום צורה שהיא (זה כולל חיבור מרחוק של אנשים אחרים באמצעות זום, Google Meet או תוכניות או אפליקציות אחרות).</strong></p>
        </>) : (
            <p>קיבלתם קישור למכשיר (מחשב או טאבלט) אחד בלבד. מותר לשחק עם כמה אנשים שתרצו, אם כולם יושבים יחד סביב מכשיר אחד. <strong>אין רשות לשדר או להפיץ את המשחק הזה בשום צורה שהיא (זה כולל חיבור מרחוק של אנשים אחרים באמצעות זום, Google Meet או תוכניות או אפליקציות אחרות).</strong></p>
        )}
        <p><strong>אם יש בעיות חיבור למשחק, נא ליצור קשר איתנו ב help@bagelsandlocks.com</strong></p>
        {children}
        <p>אל ייאוש אם לא ברור לכם מה לעשות. לפעמים הכי הטוב <strong>לקרוא את הרמז שוב</strong>. לשים לב לכל מילה. בנוסף לכך, חשוב לדעת שברוב המקרים כדי לפתור את החידות יש צורך להשתמש בכמה משאבים חשובים, הנמצאים בתוך המשחק. </p>
        <p><strong><u>תרמיל</u></strong>: במהלך כל המשחק תהיה גישה ל"תרמיל "המכיל מידע שעשוי לעזור לפתרון החידות. אפשר לגשת אליו על ידי לחיצה על סמל התיק בתחתית המסך. התרמיל הינו חלק אינטגרלי של המשחק!</p>
        <p><strong><u>רמזים</u></strong>: אם נתקעתם וזקוקים לעזרה, לחצו על סימן השאלה בתחתית המסך כדי לקבל רמז. תוכלו לבקש רמז אחד, שני רמזים ומקסימום שלושה רמזים בכל תחנה. <strong><u>חשוב לדעת</u></strong> כי עבור כל תחנה, רמז מספר שלוש נועד בעצם לגלות את התשובה, לכן השימוש בו מומלץ רק במקרה שבאמת נתקעתם! </p>
        <p><strong><u>כיצד מזינים את פתרון החידה?</u></strong><br />
        אם יש לכם את פתרון החידה, לחצו על הסמל של ה"טלפון" בתחתית המסך. בעזרת המקלדת (של המחשב שלך) הזינו את הפתרון = מספרים. אם פתרתם נכונה, תוכלו לעבור לתחנה הבאה. אם טעיתם, תוכלו לנסות שוב! אין הגבלה על מספר הניסיונות. </p>
        <p><strong><u>שינויים בדרגת הקושי של המשחק:</u></strong><br/>
        אם בשלב כלשהו במהלך המשחק תרצו לשנות את דרגת הקושי (יש שלוש רמות - ג'וניור, סטנדרט, ופרו), - תוכלו לעשות את זה על ידי לחיצה על כפתור ההגדרות בתחתית המסך. זה גם המקום בו אפשר  להתאים את צלילי המשחק.</p>
        <p><strong><u>הערכת זמן</u></strong>: הזמן המוערך הוא כשעה עד שעתיים. יש שיסיימו מהר יותר ואחרים ישחקו לאט. זה בסדר גמור! אם יש צורך בהפסקה, אפשר להתנתק ולחזור מאוחר יותר באמצעות הקישור שקיבלתם במייל כדי להמשיך. נשמור לכם את המקום!</p>
        <p>בבקשה: אנא אל תעבירו את הקישור לאף אחד אחר. הקישור מיועד למשחק שלכם בלבד. והדבר הכי חשוב - תיהנו!</p>
    </div>
}

export default function Intro(props) {
    const {i18n} = useI18n();
    const lang = i18n.getLang();
    if (lang === 'en') {
        return IntroEnglish(props);
    } else if (lang === 'he') {
        return IntroHebrew(props);
    } else {
        console.error('bad language', lang);
        return null;
    }
}
