import React from 'react';
import {
    Box,
    ModalHeader,
    // ModalFooter,
    ModalBody,
    CloseButton,
    IconButton,
    useColorMode
} from "@chakra-ui/core";
import { Rnd } from 'react-rnd';
import clsx from 'clsx';
import styles from './Dialog.module.css';
import { useI18n } from '../i18n';

const DialogContext = React.createContext({});

const dialogBodyClassName = styles.body;
const dialogHeaderClassName = styles.header;

export function Dialog({children, isOpen, onClose, boxProps, ...props}) {
    const { colorMode } = useColorMode();
    const colorModeStyles = {
        light: {
            bg: "white",
            shadow: "0 7px 14px 0 rgba(0,0,0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07)",
        },
        dark: {
            bg: "gray.700",
            shadow: `rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px`,
        },
    };
    const boxStyleProps = colorModeStyles[colorMode];

    if (!isOpen) {
        return null;
    }

    const context = {onClose};

    return (
        <DialogContext.Provider value={context}>
            <Rnd
                // cancel={'.'+dialogBodyClassName}
                dragHandleClassName={dialogHeaderClassName}
                bounds="body"
                {...props}
                className={clsx(styles.wrapper, props.className)}
            >
                <Box
                    as="section"
                    role="dialog"
                    tabIndex={-1}
                    outline={0}
                    w="100%"
                    h="100%"
                    // overflow="auto"
                    d="flex"
                    flexDir="column"
                    {...boxStyleProps}
                    {...boxProps}
                    >
                    {children}
                </Box>
            </Rnd>
        </DialogContext.Provider>
    );
}

const FOOTER_HEIGHT = 100;
Dialog.position = {
    centerWidth(_width) {
        const _innerWidth = window.innerWidth;
        const width = _width < 1 ? _innerWidth * _width : _width;
        return {
            x: (_innerWidth - width) / 2,
            width
        };
    },
    centerHeight(_height) {
        const _innerHeight = window.innerHeight - FOOTER_HEIGHT;
        const height = _height < 1 ? _innerHeight * _height : _height;
        return {
            y: ((_innerHeight - height) / 2),// - _innerHeight,
            height
        };
    },
    topQuarter(_height) {
        let centered = Dialog.position.centerHeight(_height);
        centered.y /= 2;
        return centered;
    },
    right(_width, _offset=0) {
        const _innerWidth = window.innerWidth;
        const width = _width < 1 ? _innerWidth * _width : _width;
        const offset = _offset < 1 ? _innerWidth * _offset : _offset;
        return {
            x: (_innerWidth - width - offset),
            width
        };
    },
    center(_width, _height) {
        return {
            ...Dialog.position.centerWidth(_width),
            ...Dialog.position.centerHeight(_height),
        };
    }
};

export function DialogHeader({ className, children, ...props }) {
    return (
        <ModalHeader className={clsx(className, dialogHeaderClassName)} {...props}>
            {children}
        </ModalHeader>
    )
};

export function DialogBody({className, children, ...props}) {
    return (
        <ModalBody className={clsx(className, dialogBodyClassName)} {...props}>
            {children}
        </ModalBody>
    )
};

export function DialogCloseButton(props) {
    const { flip } = useI18n();
    const ctx = React.useContext(DialogContext);
    const onClose = props.onClose || ctx.onClose;
    const {icon='close', onClose: _onClose, ...restProps} = props;
    return (
        <IconButton
            icon={icon}
            variant="solid"
            onClick={onClose}
            position="absolute"
            top="8px"
            {...flip({right: '12px'})}
            {...restProps}
        />
    );
}
