import React from 'react';
import _ from 'lodash';
import styles from './index.module.css';

import { Button, Text, SimpleGrid } from "@chakra-ui/core";
import { Dialog, DialogBody, DialogHeader, DialogCloseButton } from '../components/Dialog';

import Calculator from "awesome-react-calculator"; // FIXME: fork and improve
import PDFView from './PDFView';
import ZoomableImage from './ZoomableImage';
import { IntroContent } from '../Intro';

import { FaCalculator, FaFileAlt, FaImages, FaInfo, FaRegCopyright } from 'react-icons/fa';

import {useGameContext} from '../context';
import {useI18n} from '../i18n';

const createBackpack = _.memoize((resources, t) => {
    let backpackItems = {
        'intro': {
            id: 'intro',
            icon: FaInfo,
            label: t('labelInstructions'),
            content: <IntroContent />
        },
        'calculator': {
            id: 'calculator',
            icon: FaCalculator,
            label: t('labelCalculator'),
            content: <Calculator />
        },
    }
    resources.forEach(resource => {
        if (resource.icon === 'file-alt') {
            resource.icon = FaFileAlt;
        }
        if (resource.icon === 'copyright') {
            resource.icon = FaRegCopyright;
        }
        if (resource.icon === 'images') {
            resource.icon = FaImages;
        }
        if (!resource.content) {
            switch (resource.type) {
                case 'pdf':
                    resource.content = <PDFView url={resource.src} />;
                    break;
                case 'img':
                    resource.content = <ZoomableImage src={resource.src} alt={resource.label} />;
                    break;
                default:
                    resource.content = 'Resource not found';
            }
        }
        backpackItems[resource.id] = resource;
    });
    return backpackItems;
});

function BackpackNavButton({item, onClick}) {
    const IconComponent = item.icon;
    return (
        <Button
            maxWidth="200px"
            height="auto"
            padding="10px"
            display="flex"
            flexDirection="column"
            variantColor="darkBrown"
            onClick={onClick}
        >
            <Text as="div" fontSize="30px" margin="5px">
                <IconComponent />
            </Text>
            <Text as="div" whiteSpace="normal">
                {item.label}
            </Text>
        </Button>
    )
}

function Backpack({isOpen, toggle}) {
    const {t, flip} = useI18n('backpack');
    const {backpack: resources} = useGameContext();
    const backpackItems = createBackpack(resources, t);

    const [openItem, setOpenItem] = React.useState(null);
    React.useEffect(() => {
        if (!isOpen) {
            setOpenItem(null);
        }
    }, [isOpen]);

    return (
        <Dialog
            isOpen={isOpen}
            onClose={toggle}
            default={{ x: 50, width: 600, ...Dialog.position.centerHeight(0.8) }}
            minHeight={200}
            minWidth={300}
            maxWidth={900}
            maxHeight={700}
            className={styles.backpack}
        >
            <DialogHeader>
                {openItem ? (
                    <div className={styles.header}>
                        <Button
                            dir="ltr"
                            {...flip({
                                marginRight: 4,
                                leftIcon: 'chevron-<<left|right>>'
                            })}
                            size="xs"
                            variant="outline"
                            onClick={() => setOpenItem(null)}
                        >
                            {t('back')}
                        </Button>
                        <h3 className={styles.headerTitle}>{openItem.label}</h3>
                    </div>
                ) : (
                    <h3 className={styles.headerTitle}>{t('title')}</h3>
                )}
            </DialogHeader>
            <DialogCloseButton />
            <DialogBody>
                {openItem ? (
                    <div className={styles.content}>
                        {openItem.content}
                    </div>
                ) : (
                    <SimpleGrid minChildWidth="150px" spacing="20px" marginBottom="20px">
                        {_.map(backpackItems, (bpItem, key) => (
                            <BackpackNavButton key={key} item={bpItem} onClick={() => setOpenItem(bpItem)} />
                        ))}
                    </SimpleGrid>
                )}
            </DialogBody>
        </Dialog>
    );
}

export default Backpack;
