import React from 'react';
import {Button, Collapse, useDisclosure} from '@chakra-ui/core';

export default function ImageWithAltText({src, alt}) {
    const {isOpen, onToggle} = useDisclosure();
    return (<>
        <img src={src} alt={alt} />
        <Button onClick={onToggle} variant="link" size="sm">{isOpen ? 'Hide' : 'Show'} text</Button>
        <Collapse isOpen={isOpen} whiteSpace="pre-line">
            {alt}
        </Collapse>
    </>)
}
ImageWithAltText.displayName = 'ImageWithAltText';
