import React from 'react';
import clsx from 'clsx';
import styles from './TOL.module.css';

export const colors = ["#fff", "#01a651", "#003273", "#d2171e", "#f1f7fd", "#5f5ba5", "#e2797d", "#f5cbcb", "#80cea6", "#01aeef"];

export const SvgTreeOfLife = function SvgTreeOfLife({ level = 0, pulse = false, className, ...props } = {}, svgRef = null) {
    const color = i => i < level - 1 ? colors[i] : null;
    return (
        <svg viewBox="0 0 316 600" ref={svgRef} className={clsx(className, pulse && styles.pulse)} {...props}>
            <g fill="none" stroke="#000" strokeWidth={51.8}>
                <path d="M158 43.804L47.064 107.853" strokeWidth={20.3056} />
                <path
                    d="M158 43.804L47.064 107.853"
                    stroke="#FFF"
                    strokeWidth={17.1696}
                />
                <path d="M158 43.804l110.936 64.049" strokeWidth={20.3056} />
                <path
                    d="M158 43.804l110.936 64.049"
                    stroke="#FFF"
                    strokeWidth={17.1696}
                />
                <path d="M158 300l110.936-192.147" strokeWidth={20.3056} />
                <path
                    d="M158 300l110.936-192.147"
                    stroke="#FFF"
                    strokeWidth={17.1696}
                />
                <path d="M158 300L47.064 107.853" strokeWidth={20.3056} />
                <path d="M158 300L47.064 107.853" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M47.064 107.853l221.872 128.098" strokeWidth={20.3056} />
                <path
                    d="M47.064 107.853l221.872 128.098"
                    stroke="#FFF"
                    strokeWidth={17.1696}
                />
                <path d="M268.936 107.853L47.064 235.951" strokeWidth={20.3056} />
                <path
                    d="M268.936 107.853L47.064 235.951"
                    stroke="#FFF"
                    strokeWidth={17.1696}
                />
                <path d="M158 300l110.936-64.049" strokeWidth={20.3056} />
                <path d="M158 300l110.936-64.049" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M158 300L47.064 235.951" strokeWidth={20.3056} />
                <path d="M158 300L47.064 235.951" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M158 300l110.936 64.049" strokeWidth={20.3056} />
                <path d="M158 300l110.936 64.049" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M158 300L47.064 364.049" strokeWidth={20.3056} />
                <path d="M158 300L47.064 364.049" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M158 428.098l110.936-64.05" strokeWidth={20.3056} />
                <path
                    d="M158 428.098l110.936-64.05"
                    stroke="#FFF"
                    strokeWidth={17.1696}
                />
                <path d="M158 428.098l-110.936-64.05" strokeWidth={20.3056} />
                <path
                    d="M158 428.098l-110.936-64.05"
                    stroke="#FFF"
                    strokeWidth={17.1696}
                />
                <path d="M268.936 107.853V364.05" strokeWidth={20.3056} />
                <path d="M268.936 107.853V364.05" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M47.064 107.853V364.05" strokeWidth={20.3056} />
                <path d="M47.064 107.853V364.05" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M158 43.804v512.391" strokeWidth={20.3056} />
                <path d="M158 43.804v512.391" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M268.936 107.853H47.064" strokeWidth={20.3056} />
                <path d="M268.936 107.853H47.064" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M268.936 235.951H47.064" strokeWidth={20.3056} />
                <path d="M268.936 235.951H47.064" stroke="#FFF" strokeWidth={17.1696} />
                <path d="M268.936 364.049H47.064" strokeWidth={20.3056} />
                <path d="M268.936 364.049H47.064" stroke="#FFF" strokeWidth={17.1696} />
            </g>
            <g
                fill="#999999"
                stroke="#000"
                strokeWidth={4}
                transform="matrix(.392 0 0 -.392 158 300)"
            >
                <circle r={100} cy={653.561} fill={color(0)} />
                <circle r={100} cy={490.17} cx={-283} fill={color(1)} />
                <circle r={100} cy={490.17} cx={283} fill={color(2)} />
                <circle r={100} cy={163.39} cx={-283} fill={color(3)} />
                <circle r={100} cy={163.39} cx={283} fill={color(4)} />
                <circle r={100} fill={color(5)} />
                <circle r={100} cy={-163.39} cx={-283} fill={color(6)} />
                <circle r={100} cy={-163.39} cx={283} fill={color(7)} />
                <circle r={100} cy={-326.78} fill={color(8)} />
                <circle r={100} cy={-653.561} fill={color(9)} />
            </g>
        </svg>
    );
}

const SvgTreeOfLifeRef = React.forwardRef(SvgTreeOfLife);

export default SvgTreeOfLifeRef;
