import React from 'react';
import { Box, Image } from '@chakra-ui/core';
import clsx from 'clsx';
import { useGameState } from '../context';
import styles from './Afikoman.module.css';

import afikomanBagFront from '../content/pesachShared/img/afikoman-bag-front.png';
import afikomanBagBack from '../content/pesachShared/img/afikoman-bag-back.png';
import brokenMatzah from '../content/pesachShared/img/broken-matzah.png';

import matzahPiece1 from '../content/pesachShared/img/matzah/1.PNG';
import matzahPiece2 from '../content/pesachShared/img/matzah/2.PNG';
import matzahPiece3 from '../content/pesachShared/img/matzah/3.PNG';
import matzahPiece4 from '../content/pesachShared/img/matzah/4.PNG';
import matzahPiece5 from '../content/pesachShared/img/matzah/5.PNG';
import matzahPiece6 from '../content/pesachShared/img/matzah/6.PNG';
import matzahPiece7 from '../content/pesachShared/img/matzah/7.PNG';
import matzahPiece8 from '../content/pesachShared/img/matzah/8.PNG';
import matzahPiece9 from '../content/pesachShared/img/matzah/9.PNG';

const matzahPieces = [matzahPiece1, matzahPiece2, matzahPiece3, matzahPiece4, matzahPiece5, matzahPiece6, matzahPiece7, matzahPiece8, matzahPiece9];
const explodeAnimation = [
    'translate(-250px, 250px)',
    'translate(0, 250px)',
    'translate(250px, 250px)',
    'translate(250px, 0)',
    'translate(250px, -250px)',
    'translate(0, -250px)',
    'translate(-250px, -250px)',
    'rotate(250deg)',
    'translate(-250px, 0)',
]

function useForceRerender() {
    const [,s] = React.useState(0);
    return () => s(Date.now());
}

function Afikoman({ data, enterClue }) {
    let { level } = useGameState();
    if (level === 'TRIAL_EXPIRED') {
        level = 2; // just over here, to show the correct transition
    }
    if (level === 'CERTIFICATE') {
        level = 11;
    }

    const imgRef = React.useRef(null);
    const [pos, setPos] = React.useState([null, null]);
    const [animate, setAnimate] = React.useState(0);
    const [angle] = React.useState(() => Math.random() * 90);
    const forceRerender = useForceRerender();

    const _naturalHeight = imgRef.current?.naturalHeight;
    const onLoad = React.useCallback(() => {
        const { naturalWidth, naturalHeight, clientWidth, clientHeight } = imgRef.current || {};
        const xScale = clientWidth / naturalWidth, yScale = clientHeight / naturalHeight;
        const sizeScale = (xScale + yScale) / 2;
        console.log({imgRef, naturalWidth, naturalHeight, clientWidth, clientHeight, xScale, yScale, sizeScale});
        console.log({ data, pos });

        // Chrome has an issue with onLoad firing early before the image is fully loaded.
        // Force a rerender then to trigger onload again when it's actually loaded.
        if (!naturalWidth || !naturalHeight) { return setTimeout(forceRerender, 10) }

        // const OFFSET = level <= LVL_CUTOFF ? 360 : 419; // px at natural size

        const TOP_CENTER = ['40%', 0];
        const BAG_POS = [imgRef.current.offsetLeft + clientWidth / 2, imgRef.current.offsetTop + clientHeight / 5];

        // setPos(imgRef.current.parentElement.clientWidth - clientWidth);
        if (level === 1) {
            enterClue();
        } else if (level === 11) {
            setTimeout(() => {
                setAnimate(1);
                setTimeout(() => {
                    setAnimate(2);
                    setTimeout(enterClue, 3000);
                }, 4500);
            }, 500);
        } else {
            setTimeout(() => {
                setAnimate(1);
                setTimeout(() => setPos(TOP_CENTER), 500);
                setTimeout(() => setPos(BAG_POS), 2500);
                setTimeout(enterClue, 6000);
            }, 500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [level, enterClue, _naturalHeight]);

    console.log(animate, pos);

    return (
        <Box
            pos="absolute"
            h="100%" w="100%"
            overflow="hidden"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={styles.bg}
        >
            <Box h="97%" w="95%" position="relative">
                <Image ref={imgRef} className={clsx(styles.afikomanBag, styles.afikomanBagFront)} src={afikomanBagFront} alt="Afikoman Bag" onLoad={onLoad} />
                <Image className={clsx(styles.afikomanBag, styles.afikomanBagBack)} src={afikomanBagBack} alt="" />
                <Image
                    className={clsx(styles.matzah, animate&&styles.matzahAnimated)}
                    transform={`translateX(-50%) rotate(${-angle}deg)`}
                    left={pos[0]}
                    top={pos[1]}
                    src={brokenMatzah}
                    alt="Matzah"
                    display={level === 11 ? 'none' : null}
                />

                <Box className={clsx(styles.matzahPiecesContainer, level===11 && [styles.center, animate && styles.big])}>
                    {matzahPieces.slice(0, level-1).map((matzahPiece, i) => (
                        <Image
                            key={i}
                            src={matzahPiece}
                            className={styles.matzahPiece}
                            transform={animate === 2 ? explodeAnimation[i] : null}
                            visibility={i===level-2 && !animate ? 'hidden' : 'visible'} // last one gets shown only when animating
                        />
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default Afikoman;
