import React from 'react';
import {
    Flex,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    // PopoverHeader,
    // PopoverFooter,
    PopoverArrow,
    // PopoverCloseButton,
    Text, Icon, Button,
    useDisclosure
} from "@chakra-ui/core";
import { useI18n } from '../i18n';

export default function ResultPopover({ isCorrect, onClose: handleClose, countdown, children }) {
    const {isOpen, onClose: _onClose, onOpen} = useDisclosure();
    const { t, flip } = useI18n('comms');
    const initialFocusRef = React.useRef();
    const onClose = () => {
        _onClose();
        handleClose();
    }

    React.useEffect(() => {
        if (isCorrect === undefined) {
            _onClose();
        } else {
            setTimeout(onOpen, 10);
            // onOpen();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCorrect]);

    return (
        <Popover
            closeOnBlur={false}
            returnFocusOnClose={false}
            placement="right"
            isOpen={isCorrect !== undefined && isOpen}
            onClose={onClose}
            onOpen={onOpen}
            initialFocusRef={initialFocusRef}
        >
            <PopoverTrigger>
                {children}
            </PopoverTrigger>
            <PopoverContent zIndex={40} width="300px">
                <PopoverArrow />
                {/* <PopoverCloseButton /> */}
                {/* <PopoverHeader>Hints</PopoverHeader> */}
                <PopoverBody>
                    <Flex direction="row" alignItems="center">
                        {isCorrect ? (
                            <>
                                <Icon name="check" color="green.500" margin="4px" />
                                <Text marginX="8px" flex={1}>{t('success')}</Text>
                            </>
                        ) : (
                            <>
                                <Icon name="close" color="red.500" margin="4px" />
                                <Text marginX="8px" flex={1}>{t('invalid')}</Text>
                            </>
                        )}
                        <Button ref={initialFocusRef} dir="ltr" {...flip({ rightIcon: 'arrow-<<forward|back>>' })} onClick={onClose}>
                            {t('continue')}
                            {countdown && ` (${countdown})`}
                        </Button>
                    </Flex>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
