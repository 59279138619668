import React from 'react';
import { Box } from "@chakra-ui/core";
import styles from './index.module.css';

export default function ZoomableImage({src, alt, ...imgProps}) {
    const [isZoomed, setIsZoomed] = React.useState(false);
    const imgRef = React.useRef(null);
    const className = isZoomed ? styles.zoomableImageZoomed : styles.zoomableImageNotZoomed;
    return (
        <Box
            w={isZoomed && imgRef.current ? imgRef.current.naturalWidth : '100%'}
            h={isZoomed && imgRef.current ? imgRef.current.naturalHeight : 'auto'}
        >
            <img
                ref={imgRef}
                className={className}
                onClick={() => setIsZoomed(!isZoomed)}
                src={src}
                alt={alt}
                {...imgProps}
            />
        </Box>
    );
}