import React from 'react';
import { useI18n } from '../i18n';
import {IntroHebrew} from './Intro'; // no special Hebrew intro for jr atm

function IntroEnglish({ name, isCollaborative, children }) {
    return <div>
        <p>Welcome to {name}! Before you get started, let’s get you oriented. </p>
        {isCollaborative ? (
            <p>The link you have received allows up to 4 different devices to connect to the same game. Simply send it to the people you’ll be playing with so everyone can connect at the same time! If you are playing as a group, you can use the notepad within the game to pass notes among all the players. Some group play users like to also connect outside the game to make it easier to chat with all the players; that’s fine. However, <strong>you may not rebroadcast or redistribute this game in any way (this includes remotely connecting other people to the game via Zoom, Google Meet, or other programs or apps).</strong></p>
        ) : (
            <p>The link you have received works for ONE device only. You can play with as many people as you want, as long as you are all together around one device. <strong>You may not rebroadcast or redistribute this game in any way (this includes remotely connecting other people to the game via Zoom, Google Meet, or other programs or apps).</strong></p>
        )}
        {children}
        <p>It’s 100% ok if you don’t know what to do while you are on your quest! Sometimes the best thing to do is read your clue over again, carefully. Also, while you are on your quest, you will have access to a “Backpack” filled with information that will help you - don’t forget to use it! You can access it using the briefcase icon at the bottom of the screen. </p>
        <p>If you get stuck and feel like you need a hint, click on the ? icon at the bottom of the screen. You can ask for up to 3 hints at each stop. Keep in mind that Hint #3 is designed to basically give you the answer to the puzzle, so use it only if you’re really stuck! </p>
        <p>If you think you have the answer, you can enter it into your communication device by clicking on the cellphone icon at the bottom of your screen. Using the keyboard on your own device (i.e. your computer), enter your answer. If your answer is correct, you’ll advance to the next clue. If it’s not, you can try again!</p>
        <p>Note: this game may take you a while to finish. If you are not playing this game as a competition, and you find you need to take a break, you can simply log off and come back later to continue. We’ll save your place for you!</p>
        <p><strong>Please don’t send your login information to anyone else. Your login information is for your game ONLY.</strong> Sharing that you had fun is great, though!</p>
    </div>
}

export default function Intro(props) {
    const { i18n } = useI18n();
    const lang = i18n.getLang();
    if (lang === 'en') {
        return IntroEnglish(props);
    } else if (lang === 'he') {
        return IntroHebrew(props);
    } else {
        console.error('bad language', lang);
        return null;
    }
}
