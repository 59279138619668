import React from 'react';
import { useGameAudio, useFadeAudio } from './context';

import mainMusic from './media/Epic_Senses_-_Ephemeral.mp3';
import winMusic from './media/Alex_Che_-_Inspiring_Epic_Trailer.mp3';
import correctAudio from './media/correct.wav';
import wrongAudio from './media/wrong.wav';

const sources = {
    main: mainMusic,
    win: winMusic
}

export function BackgroundAudioController({src}) {
    const [source, setSource] = React.useState(sources[src]);
    const [audio, ref] = useGameAudio(src === 'win' ? 'fx' : 'bg', {
        src: source,
        autoPlay: true,
        loop: true,
        volume: 0.5,
    });
    const fadeAudio = useFadeAudio(0.5);
    React.useEffect(() => {
        if (source !== sources[src]) {
            fadeAudio(ref.current, false, () => {
                setSource(sources[src]);
            })
        }
    }, [src]) // eslint-disable-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        fadeAudio(ref.current, true);
    }, [source]) // eslint-disable-line react-hooks/exhaustive-deps
    return audio;
}

export function SFXAudioController({answerState}) {
    const [correctAudioEl, correctAudioRef] = useGameAudio('fx', { src: correctAudio });
    const [wrongAudioEl, wrongAudioRef] = useGameAudio('fx', { src: wrongAudio, volume: 0.3 });
    React.useEffect(() => {
        /* eslint-disable no-unused-expressions */
        if (answerState) {
            if (answerState.isCorrect) {
                correctAudioRef.current?.play();
            } else {
                wrongAudioRef.current?.play();
            }
        }
        /* eslint-enable no-unused-expressions */
    }, [answerState]); // eslint-disable-line react-hooks/exhaustive-deps
    return <>
        {correctAudioEl}
        {wrongAudioEl}
    </>
}
