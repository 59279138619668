import React from 'react';
import { Box, Image } from '@chakra-ui/core';
import clsx from 'clsx';
import { useGameState } from '../context';
import styles from './Megillah.module.css';
import {range} from 'lodash';

import scrollRightThin from '../content/purimShared/art/scroll-right-thin2.png';
import scrollRightThick from '../content/purimShared/art/scroll-right-thick2.png';
import scrollLeftText from '../content/purimShared/art/scroll-left-text2.png';
import scrollLeftBlank from '../content/purimShared/art/scroll-left-blank2.png';

import hamantash1 from '../content/purimShared/img/hamantash1.png';
import hamantash2 from '../content/purimShared/img/hamantash2.png';
import hamantash3 from '../content/purimShared/img/hamantash3.png';
import hamantash4 from '../content/purimShared/img/hamantash4.png';

const hamantashen = [hamantash1, hamantash2, hamantash3, hamantash4];
const HAMANTASH_COUNT = 15;

function useForceRerender() {
    const [,s] = React.useState(0);
    return () => s(Date.now());
}

function Hamantash({ i, onDone }) {
    const [delay] = React.useState(() => (i/5)); // + Math.random() * 0.25);
    const [size] = React.useState(() => 100 + Math.random() * 25);
    const [rotate] = React.useState(() => (360/HAMANTASH_COUNT)*i + Math.random()*2);
    React.useEffect(() => {
        setTimeout(onDone, (delay + 1) * 1000);
    }, [onDone, delay]);
    return (
        <Image
            src={hamantashen[i%4]}
            className={styles.hamantash}
            width={size + 'px'}
            animation={`${styles.hamantashAppear} 0s linear ${delay}s both`}
            transform={`rotate(${rotate}deg) translate(100%, 100%)`}
        />
    );
}
function BigHamantash() {
    const [style] = React.useState(() => ~~(Math.random() * 4));
    return (
        <Image
            src={hamantashen[style]}
            className={styles.hamantashBig}
        />
    );
}

const LVL_CUTOFF = 5;
function Megillah({ data, enterClue }) {
    let { level } = useGameState();
    if (level === 'TRIAL_EXPIRED') {
        level = 2; // just over here, to show the correct transition
    }
    if (level === 'CERTIFICATE') {
        level = 11;
    }

    const imgRef = React.useRef(null);
    const [pos, setPos] = React.useState(null);
    const [animate, setAnimate] = React.useState(0);
    const forceRerender = useForceRerender();

    const _naturalHeight = imgRef.current?.naturalHeight;
    const onLoad = React.useCallback(() => {
        const { naturalWidth, naturalHeight, clientWidth, clientHeight } = imgRef.current || {};
        const xScale = clientWidth / naturalWidth, yScale = clientHeight / naturalHeight;
        const sizeScale = (xScale + yScale) / 2;
        // console.log({imgRef, naturalWidth, naturalHeight, clientWidth, clientHeight, xScale, yScale, sizeScale});
        // console.log({ data, pos });

        // Chrome has an issue with onLoad firing early before the image is fully loaded.
        // Force a rerender then to trigger onload again when it's actually loaded.
        if (!naturalWidth || !naturalHeight) { return setTimeout(forceRerender, 10) }

        const OFFSET = level <= LVL_CUTOFF ? 360 : 419; // px at natural size

        setPos(imgRef.current.parentElement.clientWidth - clientWidth);
        if (level === 1) {
            setTimeout(enterClue, 3000);
        } else {
            setTimeout(() => {
                setPos(OFFSET * sizeScale);
                setTimeout(() => setAnimate(1), 2000);
                if (level === 11) {
                    setTimeout(() => setAnimate(2), 4500);
                } else {
                    setTimeout(enterClue, 6000);
                }
            }, 500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [level, enterClue, _naturalHeight]);

    const [hamantashCount, setHamantashCount] = React.useState(0);
    const onHamantashDone = React.useCallback(() => {
        setHamantashCount(hc => hc + 1);
    }, []);
    React.useEffect(() => {
        if (hamantashCount === HAMANTASH_COUNT) {
            setTimeout(() => setAnimate(3), 500);
            setTimeout(enterClue, 4000);
        }
    }, [hamantashCount, enterClue]);

    return (
        <Box
            pos="absolute"
            h="100%" w="100%"
            overflow="hidden"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={styles.bg}
        >
            <Box h="97%" w="95%" position="relative">
                <Image className={clsx(styles.scrollRight, animate&&styles.withBorder)} src={level <= LVL_CUTOFF ? scrollRightThin : scrollRightThick} alt="Scroll" />
                <Image ref={imgRef} className={clsx(styles.scrollLeft, animate&&styles.withBorder)} right={pos && pos + 'px'} src={level === 1 ? scrollLeftBlank : scrollLeftText} alt="Scroll" onLoad={onLoad} />
            </Box>
            {level === 11 && animate>1 && (
                range(HAMANTASH_COUNT).map(i => (
                    <Hamantash key={i} i={i} onDone={onHamantashDone} />
                ))
            )}
            {level === 11 && animate > 2 && <BigHamantash />}
        </Box>
    )
}

export default Megillah;
