import React from 'react';
import _ from 'lodash';
import firebase from './firebase';
import { useGlobalContext } from './context';
import { useI18n } from './i18n';
import PageContainer from './PageContainer';
import { LeaderboardContent } from './Leaderboard';

import {
    Box,
    SimpleGrid,
    Text,
    Button,
    Select,
    Input,
    Checkbox,
    FormControl,
    FormLabel
} from "@chakra-ui/core";
import logo from './logo.png';
import { FaListOl } from 'react-icons/fa';

const db = firebase.firestore();

function Dashboard({ configureMeta }) {
    const { t, flip } = useI18n('competition');
    const { versions, meta } = useGlobalContext();
    const [state, setState] = React.useState(null);
    React.useEffect(() => {
        if (meta.children) {
            return db.collection('gameState').doc(meta.id).onSnapshot((doc) => {
                const data = doc.data();
                // console.log(data);
                if (data) {
                    _setTeamNames(_.mapValues(data.children, 'name'));
                    if (data.name) {
                        _setName(data.name);
                    }
                }
                setState(data);
            });
        }
    }, [meta]);
    const games = state?.children;

    const [_name, _setName] = React.useState(meta.name);
    const [_version, _setVersion] = React.useState(meta.game?.version || 'regular');
    const [_canChildrenEdit, _setCanChildrenEdit] = React.useState(false);
    const [_teamNames, _setTeamNames] = React.useState({});
    const _setTeamName = (id, name) => _setTeamNames(teams => ({ ...teams, [id]: name }));
    const gameOrder = _(games).toPairs().sortBy('1.name').map('0').value();
    // console.log(state, games, gameOrder);

    const [didSubmit, setDidSubmit] = React.useState(false);
    const onContinue = () => {
        setDidSubmit(true);
        configureMeta({
            name: _name,
            version: _version,
            canChildrenUpdate: _canChildrenEdit,
            teams: _teamNames
        });
    }

    const [leaderboardOpen, setLeaderboardOpen] = React.useState(false);

    return (
        <PageContainer noFlex>
            <Box
                // h="80%"
                w="70%"
                p={5} my={15} mx="auto"
                textAlign="center"
            // className={styles.introWrapper}
            >
                <Box w="50%" mx="auto" my={5}><img src={logo} alt="Bagels and Locks Studios" /></Box>
                <Text as="h1" fontSize="4xl" my={5}>{versions.regular}</Text>
                {!state ? null : state.started ? (<>
                    <Text as="h2" fontSize="2xl" my={3}>
                        {state.name}
                    </Text>
                    <Button onClick={() => setLeaderboardOpen(lo => !lo)} variant="ghost" _hover={{ color: "gray.400" }} dir="ltr" {...flip({ leftIcon: FaListOl })}>
                        {t('leaderboard')}
                    </Button>
                    {leaderboardOpen ? <LeaderboardContent state={state} /> : <>
                        <Text as="h4">
                            {t('newTabWarning')}
                        </Text>
                        <SimpleGrid minChildWidth="150px" spacing={5}>
                            {gameOrder.map(id => (
                                <Button
                                    key={id}
                                    as="a"
                                    href={'/?gameId=' + id}
                                    size="lg"
                                    variantColor="salmon"
                                >
                                    {games[id].name}
                                </Button>
                            ))}
                        </SimpleGrid>
                    </>}
                </>) : (
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" my={5}>
                        <Text as="h2" fontSize="2xl" my={3}>
                            {t('setup')}
                        </Text>
                        <FormControl display="flex" flexDirection="row" position="relative" w="100%" maxW="500px" my={1}>
                            <FormLabel htmlFor="name" minW="75px">{t('competitionName')}</FormLabel>
                            <Input name="name" value={_name} onChange={e => { _setName(e.target.value) }} bg="lightBrown.500" color="gray.800" borderColor="initial" />
                        </FormControl>
                        <FormControl display="flex" flexDirection="row" position="relative" w="100%" maxW="500px" my={1}>
                            <FormLabel htmlFor="version" minW="75px">{t('mission')}</FormLabel>
                            <Select name="version" value={_version} onChange={e => { _setVersion(e.target.value) }} bg="lightBrown.500" color="gray.800" borderColor="initial" >
                                {_.map(versions, (name, id) => (
                                    <option key={id} value={id}>{name}</option>
                                ))}
                            </Select>
                        </FormControl>
                        <Text as="h3" fontSize="xl" my={3}>
                            {t('teams')}
                        </Text>
                        <Checkbox isChecked={_canChildrenEdit} onChange={e => _setCanChildrenEdit(e.target.checked)}>{t('allowModifyName')}</Checkbox>
                        <SimpleGrid minChildWidth="250px" spacing={5}>
                            {gameOrder.map(id => (
                                <Input
                                    key={id}
                                    name={'name-' + id}
                                    value={_teamNames[id]}
                                    onChange={e => { _setTeamName(id, e.target.value) }}
                                    bg="lightBrown.500"
                                    color="gray.800"
                                    borderColor="initial"
                                />
                            ))}
                        </SimpleGrid>
                        <Button onClick={onContinue} size="lg" my={5} variantColor="salmon" isDisabled={didSubmit}>
                            {t('submit')}
                        </Button>
                    </Box>
                )}
            </Box>
        </PageContainer>
    )
}

export default Dashboard;
