import React from 'react';
import { Box, Image, Button } from '@chakra-ui/core';
import clsx from 'clsx';
import { useGameContext } from '../context';
import styles from './Map.module.css';

function Map({data, enterClue}) {
    const {map} = useGameContext();
    const imgRef = React.useRef(null);
    const [pos, setPos] = React.useState([-1000, -1000]);
    const [size, setSize] = React.useState(0);
    const NORMAL_SIZE = 5, MOVING_SIZE = 10, ENTER_SIZE = 50, EXIT_SIZE = 2500;

    const _naturalHeight = imgRef.current?.naturalHeight;
    const onLoad = React.useCallback(() => {
        const { naturalWidth, naturalHeight, clientWidth, clientHeight } = imgRef.current || {};
        const xScale = clientWidth / naturalWidth, yScale = clientHeight / naturalHeight;
        // console.log(imgRef, naturalWidth, naturalHeight, clientWidth, clientHeight, xScale, yScale);

        // Chrome has an issue with onLoad firing early before the image is fully loaded.
        // Force a rerender then to trigger onload again when it's actually loaded.
        if (!naturalWidth || !naturalHeight) {return setTimeout(() => setPos(p => p.slice()), 10)}

        // data is null on prerender
        if (!data) return;

        const startPos = data.exiting ? [data.exiting[0] * xScale, data.exiting[1] * yScale] : null;
        const endPos = data.entering ? [data.entering[0] * xScale, data.entering[1] * yScale] : null;

        if (startPos) {
            setPos(startPos);
            setSize(NORMAL_SIZE);
            setTimeout(() => {
                if (endPos) {
                    setSize(MOVING_SIZE);
                } else {
                    setSize(EXIT_SIZE);
                    setTimeout(enterClue, 5000);
                }
            }, 10);
            if (endPos) {
                setTimeout(() => {
                    setPos(endPos);
                    setTimeout(() => {
                        setSize(NORMAL_SIZE);
                    }, 2000);
                    setTimeout(enterClue, 6000);
                }, 1000);
            }
        } else {
            setPos([0, 0]);
            setSize(ENTER_SIZE);
            setTimeout(() => {
                setPos(endPos);
            }, 100);
            setTimeout(() => {
                setSize(NORMAL_SIZE);
                setTimeout(enterClue, 5000);
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, enterClue, _naturalHeight]);

    // console.log(pos, size, size === ENTER_SIZE ? '5px' : '2px');

    return (
        <Box
            pos="absolute"
            h="100%" w="100%"
            overflow="hidden"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Box h="100%" w="auto" position="relative">
                <Image ref={imgRef} h="100%" src={map} alt="Map" onLoad={onLoad} />
                <Box
                    className={clsx(styles.mapCircle, size && styles.ready, size === EXIT_SIZE && styles.exit)}
                    left={pos[0]} top={pos[1]}
                    w={size} h={size}
                    rounded="full"
                    // borderWidth={size===ENTER_EXIT_SIZE ? '5px' : '2px'}
                />
                {/* <Button pos="absolute" top="50%" left="50%" onClick={enterClue}>Enter clue</Button> */}
            </Box>
        </Box>
    )
}

export default Map;
