import React from 'react';
import _ from 'lodash';
import { useIsCompetitive, useGameState, useGameActions } from './context';
import { useI18n } from './i18n';
import {Box, Text, Button} from '@chakra-ui/core';
import {FaListOl} from 'react-icons/fa';

export default function Timer() {
    const {t, flip} = useI18n();
    const {timer} = useGameState();
    const isCompetitive = useIsCompetitive();
    const {settings: {toggleLeaderboardOpen}} = useGameActions();

    const [time, setTime] = React.useState(timer ? formatTime(timer) : '');

    React.useEffect(() => {
        if (timer) {
            const ival = setInterval(() => {
                setTime(formatTime(timer));
            }, 500);
            return () => clearTimeout(ival);
        }
    }, [timer]);

    if (!timer) return null;

    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-around" width="100%">
            <Text fontFamily="DSeg-7" color="green.500" bg="black" border="2px solid white" p={1}>{time}</Text>
            {isCompetitive && <Button onClick={toggleLeaderboardOpen} variant="ghost" _hover={{ color: "gray.400" }} dir="ltr" {...flip({ leftIcon: FaListOl })}>{t('footer.leaderboard')}</Button>}
        </Box>
    )
}

export function calculateElapsed(timer) {
    const oldTime = timer.elapsed || 0;
    const started = timer.started || Date.now();
    return ~~((Date.now() - started + oldTime) / 1000 + ((timer.penalties||0) * 60));
}
export function formatTime(timer) {
    const elapsed = calculateElapsed(timer);
    let secs = elapsed % 60;
    let mins = ~~(elapsed / 60);
    let hours = ~~(mins / 60);
    mins = mins % 60;
    return (hours ? hours + ':' : '') + _.padStart(mins, 2, 0) + ':' + _.padStart(secs, 2, 0);
}
