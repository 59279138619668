import React from 'react';
import { Box, Text, Button, Image } from "@chakra-ui/core";
import { useGlobalContext, useGameContext } from './context';
import { useI18n } from './i18n';
import firebase from './firebase';
import logo from './logo.png';
import ParshaQuestTrialEnd from './content/breisheetShared/TrialEnd';

const games = {
    // TODO: include Hebrew games, translate
    'israelquest-challenge-one': { name: 'IsraelQuest: Challenge One', slug: 'israelquest-challenge-one' },
    'israelquest-challenge-two': { name: 'IsraelQuest: Challenge Two', slug: 'israelquest-challenge-two' },
    'israelquest-jerusalem': { name: 'IsraelQuest: The Jerusalem Secret', slug: 'israelquest-jerusalem' },
    'israelquest-tzfat': { name: 'IsraelQuest: The Kabbalah Code', slug: 'israelquest-kabbalah' },
    'holidayquest-hhd-unlocked': { name: 'HolidayQuest: High Holidays Unlocked', slug: 'holidayquest-unlocked' },
    'holidayquest-chanukah-en': { name: 'HolidayQuest: Chanukah Lights', slug: 'holidayquest-chanukah' },
    'holidayquest-purim': { name: 'HolidayQuest: A Megillah Mystery', slug: 'holidayquest-megillah' },
    'holidayquest-pesach': { name: 'HolidayQuest: The Missing Matzah', slug: 'holidayquest-matzah' },
    'parshaquest-breisheet': { name: 'ParshaQuest: Breisheet', slug: 'parshaquest-breisheet-game' },
    'parshaquest-shmot': { name: 'ParshaQuest: Shmot', slug: 'parshaquest-shmot' },
};
const missions = {
    regular: 'standard',
    pro: 'pro',
    junior: 'junior'
};

export default function SplashScreen({ onContinue, name, version }) {
    const { t } = useI18n('splash');
    const globalContext = useGlobalContext();
    const gameContext = useGameContext();
    const mustPay = (globalContext.trialExpired || !globalContext.meta.paid);
    React.useEffect(() => {
        if (mustPay) {
            firebase.analytics().setCurrentScreen('splash-mustpay');
            firebase.analytics().logEvent('screen_view');
        } else {
            firebase.analytics().setCurrentScreen('splash-returninguser');
            firebase.analytics().logEvent('screen_view');
        }
    }, [mustPay]);
    return (
        <Box
            pos="absolute"
            h="100%" w="100%"
            overflow="auto"
            backgroundColor="darkBlue.500"
            color="white"
        // display="flex"
        // alignItems="center"
        // justifyContent="center"
        >
            <Box
                // h="80%"
                w="70%"
                // overflow="auto"
                // border="10px solid black"
                p={5} my={15} mx="auto"
                // display="flex"
                // flexDirection="column"
                // alignItems="center"
                // justifyContent="flex-start"
                textAlign="center"
            // className={styles.introWrapper}
            >
                <Image src={logo} alt="" h="200px" w="auto" mx="auto" my={5} />
                <Text as="h1" fontSize="4xl" my={5}>{version || 'Invalid Game Code'}</Text>
                {globalContext.meta.gameFull && <Text as="p" fontSize="lg" my={2}>{t('gameFull')}</Text>}
                {mustPay ? (<>
                    {globalContext.meta.game && <>
                        <Text as="h2" fontSize="2xl" my={2}>{t('trialExpired')}</Text>
                        {globalContext.meta.game.game.startsWith('parshaquest') && <ParshaQuestTrialEnd game={globalContext.meta.game.game} />}
                        <Button as="a" href={`https://bagelsandlocks.com/product/${games[globalContext.meta.game.game].slug}?attribute_play-style=solo-play&attribute_level=${missions[gameContext?.id]}`} size="lg" mx={3} variantColor="salmon">{t('playRest')}</Button>

                    </>}
                    <Box display="flex" justifyContent="center" my={5}>
                        <Button as="a" href="https://bagelsandlocks.com/" size="lg" mx={3} variantColor="salmon">{t('backToWebsite')}</Button>
                        <Button as="a" href="mailto:studio@bagelsandlocks.com" size="lg" mx={3} variantColor="salmon">{t('emailUs')}</Button>
                    </Box>
                </>) : (<>
                    <Text as="h2" fontSize="2xl" my={2}>{t('welcomeBack', name)}</Text>
                    <Button onClick={onContinue} size="lg" my={5} variantColor="salmon">{t('play')}</Button>
                </>)}
            </Box>
        </Box>
    )
}
