import React from 'react';
import styles from './GameContainer.module.css';
import clsx from 'clsx';

import { Textarea, Icon, Text } from "@chakra-ui/core";

import { useI18n } from './i18n';
import { useGameState, useGameActions, useIsCollaborative, useCollaborativeState } from './context';

import { Dialog, DialogBody, DialogHeader, DialogCloseButton } from './components/Dialog';

export default function Notes({ isOpen, toggle }) {
    const {notes} = useGameState();
    const {setNotes, saveNotes} = useGameActions();
    const {t, rtlClass} = useI18n('notepad');

    const isCollaborative = useIsCollaborative();
    const [isLocked, setLocked] = useCollaborativeState('editing-notes', false);
    const [isEditing, setEditing] = React.useState(false);
    const [timeoutId, setTimeoutId] = React.useState(null);
    const INACTIVE_TIMEOUT = 10;

    const onChange = e => {
        setNotes(e.target.value);
        setEditing(true);
        setLocked(true);
        clearTimeout(timeoutId);
        const target = e.target;
        setTimeoutId(setTimeout(() => target.blur(), INACTIVE_TIMEOUT*1000)); // unlock after 10 secs of inactivity
    }
    const onBlur = () => {
        saveNotes(notes);
        setEditing(false);
        setLocked(false);
    }
    return (
        <Dialog
            isOpen={isOpen}
            onClose={toggle}
            default={{ y: 200, height: 300, ...Dialog.position.right(350, 0.1) }}
            minHeight={200}
            minWidth={300}
            maxWidth={900}
            maxHeight={700}
            className={clsx(styles.notesContainer, rtlClass)}
        >
            <DialogHeader>
                {t('title')}
            </DialogHeader>
            <DialogCloseButton />
            <DialogBody>
                {isCollaborative && isLocked && (
                    isEditing
                        ? <Text fontSize="sm">{t('lockedForOthers')} <Icon name="unlock" /></Text>
                        : <Text fontSize="sm">{t('lockedForMe')} <Icon name="lock" /></Text>
                )}
                <Textarea
                    value={notes}
                    isReadOnly={isLocked && !isEditing}
                    _readOnly={{bg: '#aaa'}}
                    onChange={onChange}
                    onBlur={onBlur}
                    isFullWidth
                    height="100%"
                    resize="none"
                    placeholder={t('placeholder')}
                />
            </DialogBody>
        </Dialog>
    );
}
