import _ from 'lodash';
import React from 'react';
import { Input, Tooltip, useDisclosure } from "@chakra-ui/core";
import InputMask from 'react-input-mask';
import { useI18n } from '../i18n';

const InnerInput = React.forwardRef(function InnerInput({value, onChange, placeholder, borderColor, ...props}, ref) {
    return (
        <Input
            ref={ref}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            dir="ltr"
            textAlign="center"
            marginBottom={2}
            flex={1}
            minWidth="100px"
            borderColor={borderColor}
            borderWidth={borderColor && '4px'}
            {...props}
        />
    )
});

const MaskedInput = React.forwardRef(function MaskedInput({mask, value, onChange, onFocus, onBlur, ...props}, ref) {
    const unmask = val => mask ? val.replace(/(_| )/g, '') : val;
    const handleChange = e => {
        const val = e.target.value;
        const newVal = unmask(val);
        onChange(newVal);
    }
    if (!mask) {
        return (
            <InnerInput
                ref={ref}
                value={value}
                onChange={handleChange}
                // onFocus={onFocus}
                // onBlur={onBlur}
                {...props}
            />
        );
    }

    const beforeMaskedValueChange = (newState, oldState, userInput, maskOptions) => {
        // console.log(newState, oldState, userInput, maskOptions);
        if (userInput && newState.value === oldState.value) {
            // check if the cursor was just in the wrong spot
            const maskedIndex = newState.value.indexOf(maskOptions.maskChar);
            const regex = maskOptions.formatChars[maskOptions.mask[maskedIndex]];
            if (regex && userInput.match(regex)) {
                const newValue = newState.value.slice(0, maskedIndex) + userInput + newState.value.slice(maskedIndex+1);
                return {...newState, value: newValue};
            }
        }
        return newState
    }
    return (
        <InputMask
            mask={mask}
            alwaysShowMask
            value={value}
            onChange={handleChange}
            // onFocus={onFocus}
            // onBlur={onBlur}
            beforeMaskedValueChange={beforeMaskedValueChange}
        >
            {(inputProps) => (
                <InnerInput
                    ref={ref}
                    {...inputProps}
                    {...props}
                />
            )}
        </InputMask>
    );
});

const AnswerScreen = React.forwardRef(function AnswerScreen({answerType, value, onChange,...props}, ref) {
    const { t } = useI18n('comms.badInputLabel');
    let mask, placeholder = null, badInputLabel;

    if (answerType.type === 'word') {
        mask = null;
        placeholder = _.repeat('_____ ', answerType.length);
    } else {
        mask = _.repeat(answerType.type === 'number' ? '9 ' : 'a ', answerType.length).trim();
        badInputLabel = 'This input only accepts ' + answerType.type + 's';
        badInputLabel = t('message', t(answerType.type));
    }

    const {isOpen, onClose, onOpen} = useDisclosure();
    const _onChange = (newVal) => {
        // display notification of invalid input
        if (value === newVal) {
            onOpen();
            setTimeout(onClose, 5000);
        } else {
            onClose();
        }
        onChange(newVal)
    }

    return (
        <Tooltip isOpen={isOpen} hasArrow placement="top" label={badInputLabel} zIndex={50} bg="red.500">
            <MaskedInput
                ref={ref}
                mask={mask}
                value={value}
                onChange={_onChange}
                placeholder={placeholder}
                {...props}
            />
        </Tooltip>
    );
});

export default AnswerScreen;
