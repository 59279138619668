import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, CSSReset, theme } from "@chakra-ui/core";

import './firebase';

import './index.css';
import './content/styles.module.css';

// import App from './App';
import GlobalController from './GlobalController';

import * as serviceWorker from './serviceWorker';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div id="error">
        <h1>Sorry! Something went wrong.</h1>
        <h2><a href="">Click here to reload</a> -- you'll get right back to your game.</h2>
      </div>;
    }

    return this.props.children;
  }
}

const Loading = () => 'Loading...';

function StupidRouter({children}) {
  // const adminComponentRef = React.useRef(Loading);
  const [Admin, setAdminComponent] = React.useState(() => Loading);
  React.useEffect(() => {
    if (window.location.pathname === '/admin') {
      import('./admin').then((res) => {
        setAdminComponent(() => res.default);
      }).catch(e => {
        console.error(e);
        setAdminComponent(null);
      });
    }
  }, []);
  if (window.location.pathname === '/admin') {
    // const Admin = adminComponentRef.current;
    return <Admin />;
  }
  return children;
}

const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    darkBlue: { 500: '#072f7b' },
    salmon: { 500: '#ef7407' },
    lightBrown: { 500: '#f8d193' },
    darkBrown: { 500: '#734b2f' }
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <StupidRouter>
        <ThemeProvider theme={customTheme}>
          <CSSReset />
          <GlobalController />
        </ThemeProvider>
      </StupidRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
