import React from 'react';

import { BackgroundAudioController, SFXAudioController } from './AudioController';
import GameContainer from './GameContainer';
import { GameContext, GameSettingsContext, _gameSettings, GameStateContext, GameActionsContext, useGlobalContext } from './context';
import VersionSelector from './VersionSelector';

import firebase from './firebase';
import { stateManager, bindActions } from './stateManager';

const db = firebase.firestore();

function GameController({ dataManager }) {
    const { meta } = useGlobalContext();
    // const dataManager = makeClientDataManager(debug);
    const { initialState, actions } = stateManager(dataManager, meta.features);
    const [gameState, setGameState] = React.useState(initialState);
    const gameObject = gameState?.gameObject;

    const [gameSettings, setGameSettings] = React.useState(_gameSettings);

    React.useEffect(() => {
        if (gameObject?.name) {
            firebase.analytics().logEvent('version_switched', { version_id: gameObject.name });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameObject?.name]);

    React.useEffect(() => {
        // console.log('effect running', meta.id);

        if (meta.features?.collaborative) {
            return db.collection('gameState').doc(meta.id).onSnapshot((doc) => {
                let newState = doc.data();
                if (newState) {
                    newState = dataManager.deserializeGameState(newState);
                    setGameState(gameState => {
                        // console.log(gameState);
                        // console.log(newState);
                        if (gameState.level === newState.level) {
                            // state from server includes map data, which we don't want to pass on unless relevant
                            newState.mapData = gameState.mapData;
                        }
                        return newState;
                    });
                }
            });
        }
    }, [meta.id, dataManager, meta.features]);

    const [oldOnlineObj, setOldOnlineObj] = React.useState(null);
    const _timer = gameState?.timer?.started ? gameState.timer : gameState?.oldTimer;
    React.useEffect(() => {
        // console.log('running snapshot subscribe effect');
        return firebase.firestore().doc(`/gameState/${meta.id}/users/${firebase.auth().currentUser.uid}`).onSnapshot(doc => {
            // console.log('firestore online status updated', doc.data(), _timer, 'old', oldOnlineObj, 'current timer', gameState?.timer, 'old timer', gameState?.oldTimer);
            setOldOnlineObj(doc.data());
            // if coming back online (the object has been updated with the server timestamp)
            if (
                doc.data() && doc.data().lastChanged !== null && doc.data().active
                && (
                    (oldOnlineObj && oldOnlineObj.lastChanged === null && oldOnlineObj.active)
                    || (oldOnlineObj && !oldOnlineObj.active)
                )
            ) {
                // console.log('restart timer');
                db.collection('gameState').doc(meta.id).update({ timer: _timer }).catch(e => {
                    // permissions error because the timer shouldn't actually be restarted
                    // console.warn('error updating timer', e)
                });
            }
        });
        // I know what i'm doing here. Only rerun this when the online object cache changes. At that point we will get the fresh timer by default.
        // Stringify it because otherwise it will constantly run and get a fresh object that is semantically equivalent but different reference.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(oldOnlineObj)]);

    console.log(gameObject, gameState);

    const boundActions = {
        ...bindActions(actions, gameState, setGameState),
        saveNotes: dataManager.fetchData.setNotes,
        saveName: dataManager.fetchData.setName,
        resetAnswerCollab: dataManager.fetchData.resetAnswer,
        settings: {
            toggleBg: () => setGameSettings(gs => ({ ...gs, sound: { ...gs.sound, bg: !gs.sound.bg } })),
            toggleFx: () => setGameSettings(gs => ({ ...gs, sound: { ...gs.sound, fx: !gs.sound.fx } })),
            setVolume: (volume) => setGameSettings(gs => ({ ...gs, sound: { ...gs.sound, volume } })),
            toggleLeaderboardOpen: () => setGameSettings(gs => ({ ...gs, leaderboardOpen: !gs.leaderboardOpen })),
        },
        forceReload: dataManager.forceReload
    };

    if (!gameObject) {
        return <VersionSelector setVersion={boundActions.switchGameVersion} setName={boundActions.saveName} forceReload={boundActions.forceReload} />
    }

    return gameState && (
        <GameSettingsContext.Provider value={gameSettings}>
            <GameContext.Provider value={gameObject}>
                <GameStateContext.Provider value={gameState}>
                    <GameActionsContext.Provider value={boundActions}>
                        <BackgroundAudioController src={(gameState.levelData?.content || gameState.mapData) ? 'main' : 'win'} />
                        {!!gameState.levelData && <SFXAudioController answerState={gameState.answer} />}
                        <GameContainer key={gameState.level} debug={!!process.env.REACT_APP_DEV_MODE} />
                    </GameActionsContext.Provider>
                </GameStateContext.Provider>
            </GameContext.Provider>
        </GameSettingsContext.Provider>
    );
}

export default GameController;
