import React from 'react';
import {Box, Image, useDisclosure} from '@chakra-ui/core';
import OutsideClickHandler from 'react-outside-click-handler';
import useResizeAware from 'react-resize-aware';

const closedStyles = {
    maxH: '100%',
    maxW: '100%'
};
const openStyles = (zoom, height, img) => {
    let fullWidth = img.naturalWidth / (img.naturalHeight / zoom);
    return {
        maxH: zoom,
        maxW: 'none',
        position: 'relative',
        left: (fullWidth - (fullWidth / Math.min(img.naturalHeight, zoom) * height)) / -(zoom / height), // -50%
        top: (Math.min(img.naturalHeight, zoom) - height) / -(zoom / height) // -50%
    }
};

export default function ImageWithRolloverZoom({src, alt, height, zoom=height*2, containerStyles={}}) {
    const [resizeListener] = useResizeAware();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [didClick, setDidClick] = React.useState(false); // whether the image was opened by clicking (true) or mouseover (false)
    const _onOpen = click => () => {
        if (!(isOpen && didClick)) { // if it was opened with a click, don't change it to mouse
            onOpen();
            setDidClick(click);
        }
    }
    const _onClose = click => () => {
        if (click === didClick) { // only apply to the same method
            onClose();
            setDidClick(false);
        }
    }
    const _onToggle = click => () => {
        if (isOpen && (click === didClick)) {
            _onClose(click)();
        } else {
            _onOpen(click)();
        }
    }

    // keep the image's size and place
    const imgRef = React.useRef();
    if (height <= 1) { // treat as percentage
        const _h = height;
        if (imgRef.current) {
            const upperContainer = imgRef.current.parentElement.parentElement.parentElement;
            height = height * upperContainer.clientHeight;
            zoom = (zoom / _h) * upperContainer.clientHeight;
        } else {
            height = null;
        }
    }
    const natWidth = isOpen && imgRef.current && (imgRef.current.naturalWidth / imgRef.current.naturalHeight * Math.min(imgRef.current.clientHeight, height));

    let _containerStyles = (isOpen ? containerStyles.open : containerStyles.closed) || {};

    return (
        <OutsideClickHandler onOutsideClick={_onClose(true)}>
            <Box height={height} width={natWidth} {..._containerStyles}>
                {resizeListener}
                <Image
                    ref={imgRef}
                    src={src}
                    alt={alt}
                    onMouseEnter={_onOpen(false)}
                    onMouseLeave={_onClose(false)}
                    onClick={_onToggle(true)}
                    {...isOpen ? openStyles(zoom, height, imgRef.current) : closedStyles}
                    />
            </Box>
        </OutsideClickHandler>
    );
}
ImageWithRolloverZoom.displayName = 'ImageWithRolloverZoom';
