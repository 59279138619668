import React from 'react';
import { useI18n } from '../i18n';

function IntroEnglish({name, children}) {
    return <div>
        <p>You have just joined a Competitive Game of {name}! The link you have received allows you to connect to your game, and your teammates (if applicable) will be joining you. You can use the notepad within the game to pass notes among teammates. Some teams like to also connect outside the game to make it easier to chat with all the players. However, <strong>you may not rebroadcast or redistribute this game in any way (this includes remotely connecting other people to the game via Zoom, Google Meet, or other programs or apps).</strong></p>
        {children}
        <p>Don’t get discouraged if you don’t know what to do immediately. Sometimes the best thing to do is read your clue over again, carefully, paying extra careful attention to the wording. In most cases, you will not be able to solve the puzzles just by reading them - so we’re providing you with some resources. Throughout your quest, you will have access to a “Backpack”, that contains information that may help you. You can access these resources by clicking on the briefcase icon at the bottom of the screen.  You can communicate with the other members of your team (if you’re working with a team) by typing on the notepad you’ll find on the bottom of your screen. Click the notepad icon to open it and type. If it’s “locked” that means someone else on your team is typing, so just be patient and wait until they’ve finished sending their message. When they are done, you’ll be able to type your note to the team.</p>
        <p>If you get stuck and feel like you need a hint, you will need to decide (as a team if you’re in one) whether to get a hint. If your team agrees, click on the ? icon at the bottom of the screen for a hint. However, keep in mind that hints come with time penalties (Hint #1 &mdash; 3 minute penalty, Hint #2 &mdash; 6 minute penalty, Hint #3 &mdash; 12 minute penalty) &mdash; so you’ll see your clock advance when you get a hint. You can get up to 3 hints at each station. Only use hint #3 if you’re really stuck!</p>
        <p>If you want to see how the competition is going, you can click on the “Leaderboard” to see where you’re standing. To get back to your game, click the X at the top right of the Leaderboard screen. <strong>DO NOT HIT THE BACK BUTTON on your browser during the game!</strong></p>
        <p><strong>If at any time during the game, the game is not responsive, or you see an error screen, just reload the page! It will resume where you left off. If you are having persistent trouble, please reach out to us on WhatsApp or email us at help@bagelsandlocks.com</strong></p>
        <p>If you believe you have the solution to your puzzle, any team member can enter it into your communication device by clicking on the cellphone icon at the bottom of your screen. Using the keyboard on your own device (i.e. your computer), enter your solution (letters or numbers). If your answer is correct, you’ll advance to the next clue when everyone on your team clicks the “Continue” button. If it’s not, you can try again! There is no limit to the number of times you can try to enter a solution.</p>
        <p>Click on “Settings” at the bottom of your screen to adjust the game sounds.</p>
        <p><strong>Please don’t send your login information to anyone else. Your login information is for your game ONLY.</strong> You can recommend the game to your friends on social media using the share button at the end of the game.</p>
    </div>
}

function IntroHebrew({ name, children }) {
    return <div>
        <p>ברוכים הבאים ל-{name}! לפני התחלת המשחק, נדגיש כמה דברים חשובים. הצטרפתם למשחק "תחרותי" - הקישור שלכם מאפשר התחברות למשחק או כ"קבוצה" של מכשיר (מחשב או טאבלט) אחד, או כחבר בקבוצה המורכבת מכמה חיבורים. מותר לשחק עם כמה אנשים שתרצו סביב מכשיר אחד. <strong>אין רשות לשדר או להפיץ את המשחק הזה בשום צורה שהיא (זה כולל חיבור מרחוק של אנשים אחרים באמצעות זום, Google Meet או תוכניות או אפליקציות אחרות).</strong> </p>
        <p>בזמן המשחק, אם אתם משחקים בקבוצה, תוכלו להשתמש במחברת הנמצאת בתוך המשחק כדי להעביר תווים בין כל השחקנים. בזמן משחק קבוצתי יש שמעדיפים להתחבר גם מחוץ למשחק כדי לאפשר בצ'ט לכל המשתתפים לדבר ביניהם - וזה עובד מעולה, כל עוד לא משדרים או מפיצים את המשחק הזה בשום צורה שהיא.</p>
        {children}
        <p>כדי להתאים את צלילי המשחק, לחצו על "הגדרות" בתחתית המסך.</p>
        <p>אל ייאוש, אם לא ברור לכם מה לעשות. לפעמים הכי טוב <strong>לקרוא את הרמז שוב</strong>, ולשים לב לכל מילה. בנוסף לכך, חשוב לדעת שברוב המקרים כדי לפתור את החידות יש צורך להשתמש בכמה משאבים חשובים, הנמצאים בתוך המשחק. </p>
        <p><strong><u>תרמיל</u></strong>: במהלך כל המשחק תהיה גישה ל"תרמיל "המכיל מידע שעשוי לעזור לפתרון החידות. אפשר לגשת אליו על ידי לחיצה על סמל התיק בתחתית המסך. </p>
        <p><strong><u>רמזים</u></strong>: אם נתקעתם וזקוקים לעזרה, לחצו על סימן השאלה בתחתית המסך כדי לקבל רמז. תוכלו לבקש רמז אחד, שני רמזים ומקסימום שלושה רמזים בכל תחנה. חשוב לדעת כי עבור כל תחנה, רמז מספר שלוש נועד בעצם לגלות את התשובה, לכן השימוש בו מומלץ רק במקרה שבאמת נתקעתם!  עם זאת, זכרו כי אתם משתתפים בתחרות, לכן קבלת רמזים מגיעים עם הוספת זמן לשעון (רמז מס '1 - קנס של 3 דקות, רמז # 2 - קנס של 6 דקות, רמז # 3 - קנס של 12 דקות). </p>
        <p><strong><u>כיצד מזינים את פתרון החידה?</u></strong><br />
        אם יש לכם את פתרון החידה, לחצו על הסמל של ה"טלפון" בתחתית המסך. בעזרת המקלדת (של המחשב שלכם) הזינו את הפתרון (מספרים או אותיות). אם פתרתם נכונה, תוכלו לעבור לתחנה הבאה. אם טעיתם, תוכלו לנסות שוב! אין הגבלה על מספר הניסיונות.</p>
        <p>כדי לראות את המיקום שלכם בתחרות, לחצו על הקישור "לוח ניקוד" כדי לראות איפה כל קבוצה עומדת. כדי לחזור למשחק, לחצו על ה- X בפינה השמאלית העליונה של מסך לוח הניקוד. <strong>בזמן המשחק אין להשתמש בכפתור חזור בדפדפן.</strong></p>
        <p><strong>אם במהלך המשחק המשחק אינו מגיב, או אתם רואים מסך שגיאה, פשוט טענו את הדף מחדש כדי לחזור למקום שהפסקתם. אם התקלה מתמשכת, נא צרו קשר איתנו במייל או בוואצפ help@bagelsandlocks.com / 055-918-2207</strong></p>
        <p><strong><u>הערכת זמן</u></strong>: הזמן המוערך הוא כשעה עד שעתיים. יש שיסיימו מהר יותר ואחרים ישחקו לאט. זה בסדר גמור! אם יש צורך בהפסקה, אפשר להתנתק ולחזור מאוחר יותר באמצעות אותו הקישור שקיבלתם לפני תחילת המשחק. נשמור לכם את המקום!</p>
        <p>בבקשה: אנא אל תעבירו את הקישור לאף אחד אחר. הקישור מיועד למשחק שלכם בלבד. והדבר הכי חשוב - תיהנו!</p>
    </div>
}

export default function Intro(props) {
    const { i18n } = useI18n();
    const lang = i18n.getLang();
    if (lang === 'en') {
        return IntroEnglish(props);
    } else if (lang === 'he') {
        return IntroHebrew(props);
    } else {
        console.error('bad language', lang);
        return null;
    }
}
