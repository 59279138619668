import React from 'react';
import firebase from './firebase';
import { useIsCompetitive } from './context';
import { useI18n } from './i18n';

import {
    Box,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    // PopoverHeader,
    // PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Icon, CircularProgress, Button,
    Text
} from "@chakra-ui/core";

const MAX_HINTS = 3;

function HintsBody({hints, getHint: _getHint}) {
    const isCompetitive = useIsCompetitive();
    const { t } = useI18n('hints');

    const [isRefreshing, setIsRefreshing] = React.useState(false);
    const getHint = React.useCallback(() => {
        if (!isRefreshing) {
            setIsRefreshing(true);
            _getHint().then(() => {
                setIsRefreshing(false);
            });
        }
    }, [_getHint, isRefreshing]);

    const handleChange = (newIndex) => {
        if (newIndex === hints.length) { // the last one
            getHint();
        }
    }

    if (hints.length === 0) {
        return (
            <Box
                // h="120px"
                // w="auto"
                // overflow="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                textAlign="center"
            >
                <Button variantColor="blue" onClick={getHint} isDisabled={isRefreshing} isLoading={isRefreshing}>
                    {t('click')}
                </Button>
                {isCompetitive && <Text>{t('penaltyWarning')}</Text>}
                <Text>{t('ifNotWorking')}</Text>
                <Text>{t('instructionsInBackpack')}</Text>
            </Box>
        );
    }


    return (
        <Tabs
            size="md"
            isManual // don't automatically focus new tab because it might be the get hint button
            onChange={handleChange}
        >
            <TabList>
                {hints.map((hint, i) => (
                    <Tab key={i}>{t('hintN', i+1)}</Tab>
                ))}
                {hints.length < MAX_HINTS && (
                    <Tab isDisabled={isRefreshing}>
                        <Icon name="small-add" />
                    </Tab>
                )}
            </TabList>

            <TabPanels>
                {hints.map((HintComponent, i) => (
                    <TabPanel key={i}>
                        <Box
                            h="200px"
                            w="auto"
                            overflow="auto"
                        >
                            <HintComponent />
                        </Box>
                    </TabPanel>
                ))}
                {hints.length < MAX_HINTS && (
                    <TabPanel>
                        <Box
                            h="200px"
                            w="auto"
                            overflow="auto"
                        >
                            {hints.length === 0 ? (<>
                                <Button variantColor="blue" onClick={getHint}>
                                    {t('click')}
                                </Button>
                                {isCompetitive && <Text>{t('penaltyWarning')}</Text>}
                            </>) : (
                                <CircularProgress isIndeterminate />
                            )}
                        </Box>
                    </TabPanel>
                )}
            </TabPanels>
        </Tabs>
    );
}

export default function Hints({hints, getHint, button}) {
    const { rtlClass } = useI18n();
    const _getHint = () => {
        firebase.analytics().logEvent('get_hint', {hint_num: hints.length+1});
        return getHint();
    }
    return (
        <Popover closeOnBlur={false}>
            <PopoverTrigger>
                {button}
            </PopoverTrigger>
            <PopoverContent zIndex={30} className={rtlClass}>
                <PopoverArrow />
                <PopoverCloseButton />
                {/* <PopoverHeader>Hints</PopoverHeader> */}
                <PopoverBody>
                    <HintsBody hints={hints} getHint={_getHint} />
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
