const he = {
    __dir: 'rtl',
    versionSelector: {
        beforeYouBegin: 'לפני התחלת הקווסט, נא וודאו את שמכם',
        name: 'שם:',
        playerName: 'שם השחקן:',
        teamName: 'שם הקבוצה:',
        game: 'משחק:',
        selectGame: 'בחר משחק',
        mission: 'משימה:',
        players: 'שחקנים:',
        notReady: 'לא מוכן',
        begin: 'החל את הקווסט',
        dontWorryChangeLevel: 'אל דאגה! אם בשלב כלשהו במהלך המשחק תחליט שאתה מעדיף דרגת קושי אחרת, תוכלו להחליף אותו על ידי לחיצה על כפתור ההגדרות בתחתית המסך.',
        hoverInfoJunior: '%s% - מיעוד לילדים בגילאי 9-13, או או עבור מתחילים (או שחקנים צעירים יותר עם מבוגר שעוזר).',
        hoverInfoStandard: '%s% - מיועד למבוגרים ובני נוער בגילאי 14+ - למתחילים או מנוסים.',
        hoverInfoPro: '%s% - מיועד למבוגרים ובני נוער בגילאי 15+, שהם שחקנים מנוסים.',
        hoverInfoName: 'השם המופיע כאן גם יופיע על תעודת הסיום שלך.',
        hoverInfoPlayerName: 'החברים לקבוצה יראו את השם הזה.',
    },
    intro: {
        begin: 'החל את הקווסט',
        notReady: 'לא מוכן',
        goodLuck: 'עכשיו אתם מוכנים להתחיל! בהצלחה - הקליקו בכפתור למטה לעבור למשחק!',
    },
    footer: {
        currentLocation: 'מיקום נוכחי:',
        clueX: 'חידה %s%',
        labelCommunications: 'מכשיר קשר',
        labelBackpack: 'תרמיל',
        labelNotes: 'מחברת',
        labelHint: 'רמזים',
        labelSettings: 'הגדרות',
        leaderboard: 'לוח ניקוד',
    },
    hints: {
        click: 'הקליקו לקבלת רמז',
        penaltyWarning: 'אזהרה: אם תחליטו לקבל רמז, תקבל "קנס" של הוספת זמן על השעון.',
        ifNotWorking: "אם המשחק אינו מגיב כראוי, טען את הדף מחדש. שמרנו לכם את המקום.",
        instructionsInBackpack: 'ניתן למצוא את הוראות המשחק בתוך התרמיל.',
        hintN: 'רמז %s%',
    },
    settings: {
        title: 'הגדרות',
        sound: 'צלילים',
        bgSound: 'צלילי רקע',
        sfx: 'צלילים שונים',
        volume: 'עצמת הצלילים',
        changeVersion: 'בחר דרגת קושי',
        versionSelect: {
            select: 'בחר',
            title: 'החלפת דרגת קושי',
            areYouSure: 'וודאו שזה לא טעות - אתם עומדים להחליף ל: %s%?',
            cancel: 'בטל',
            switch: 'החלף',
        }
    },
    notepad: {
        title: 'מחברת',
        placeholder: 'רשמו כאן מחשבות ודברים שרציתם לזכור',
        lockedForOthers: 'המחברת נעול כעת לשחקנים אחרים.',
        lockedForMe: 'מישהו אחר כותב במחברת כעת.',
    },
    backpack: {
        title: 'תרמיל',
        back: 'חזרה',
        labelInstructions: 'כללי המשחק',
        labelCalculator: 'מחשבון',
        zoomIn: 'הגדלה',
        zoomOut: 'הקטנה'
    },
    comms: {
        submit: 'שלח',
        success: 'הקוד עבר בהצלחה!',
        invalid: 'קוד שגוי!',
        continue: 'המשך',
        continuingIn: 'ממשיך בעוד %s%',
        useKeyboardMessage: 'הקליקו למעלה והזינו את הפתרון האמצעות המקלדת שלכם',
        badInputLabel: {
            message: 'כאן ניתן להזין רק %s%',
            letter: 'אותיות אנגלית',
            number: 'מספרים'
        }
    },
    clickToOpen: "הקליקו כאן לפתוח",
    certificate: {
        congratulations: 'כל הכבוד',
        youCompleted: 'סיימתם בהצלחה את משימת',
        finalTime: 'זמן סופי: %s%',
        yourImpressiveSkillSet: 'יש לך כישרונות מרשימות שהראה לנו שאת/ה מסוגל להיות חבר בצוות העילית שלנו.',
        getReady: 'חשוב שתהיו מוכנים למשימה הבאה - ניצור איתכם קשר שוב בקרוב!',
        shareScreenshot: 'שתף צילום מסך ברשתות החברתיות וטייגו אותנו!',
        exit: 'יציאה מהמשחק',
        seeLeaderboard: 'הצג לוח ניקוד',
        checkOutOutOtherGames: 'משחקים נוספים (בקרוב: משחקים נוספים בעברית)',
    },
    splash: {
        gameFull: 'אוף - המשחק כבר מלא!',
        trialExpired: 'הגעתם לסוף ההצצה החינמית',
        playRest: 'הקליקו כאן להמשיך',
        backToWebsite: 'לאתר הראשי',
        emailUs: 'צור קשר',
        welcomeBack: 'ברוכים השבים, %s%',
        play: 'התחילו',
    },
    leaderboard: {
        rank: 'מיקום בתחרות',
        team: 'קבוצה',
        level: 'תחנה',
        time: 'שעון',
        waiting: 'מחכים...',
        finished: 'סיימו',
        intro: 'הקדמה',
    },
    competition: {
        setup: 'הגדרת התחרות',
        competitionName: 'שם התחרות:',
        mission: 'משימה:',
        teams: 'שמות קבוצות',
        allowModifyName: 'אפשר לשחקנים לשנות את שמות הקבוצות',
        submit: 'סיום',
        leaderboard: 'לוח ניקוד',
        newTabWarning: 'ניתן להתחיל לשחק דרך הכפתור לקבוצה שלך כאן - נא לפתוח ישר, ולא בטאב חדש',
    },
}
export default he;
