import Map from './Map';
import PuzzleTransition from './PuzzleTransition';
import Calendar from './Calendar';
import Sefirot from './Sefirot';
import Chanukiah from './Chanukiah';
import Megillah from './Megillah';
import Afikoman from './Afikoman';
import Torah from './Torah';

const transitionScreens = {
    map: Map,
    puzzle: PuzzleTransition,
    calendar: Calendar,
    sefirot: Sefirot,
    chanukiah: Chanukiah,
    megillah: Megillah,
    afikoman: Afikoman,
    torah: Torah,
};

export default transitionScreens;
