import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

let firebaseConfig = {
    apiKey: "AIzaSyAxsFkmUrjcu-5QX1LjVLAyjmWwINiJUeM",
    authDomain: "maximal-port-275010.firebaseapp.com",
    // databaseURL: "https://maximal-port-275010.firebaseio.com",
    projectId: "maximal-port-275010",
    // storageBucket: "maximal-port-275010.appspot.com",
    // messagingSenderId: "255625526467",
    appId: "1:255625526467:web:3b4c0ba719a11d21423676",
    measurementId: "G-MENTT5NHS6"
};
if (!process.env.REACT_APP_DEV_MODE) {
    if (process.env.FIREBASE_PROJECT === 'maximal-port-275010') {
        firebaseConfig = {
            apiKey: "AIzaSyAxsFkmUrjcu-5QX1LjVLAyjmWwINiJUeM",
            authDomain: "maximal-port-275010.firebaseapp.com",
            databaseURL: "https://maximal-port-275010.firebaseio.com",
            projectId: "maximal-port-275010",
            storageBucket: "maximal-port-275010.appspot.com",
            messagingSenderId: "255625526467",
            appId: "1:255625526467:web:3b4c0ba719a11d21423676",
            measurementId: "G-MENTT5NHS6"
        };
    }
    if (process.env.FIREBASE_PROJECT === 'bagels-and-locks-testing') {
        firebaseConfig = {
            apiKey: "AIzaSyBq0xismLah9d-8YXYFCnn9CiJSuUAcIEo",
            authDomain: "bagels-and-locks-testing.firebaseapp.com",
            databaseURL: "https://bagels-and-locks-testing.firebaseio.com",
            projectId: "bagels-and-locks-testing",
            storageBucket: "bagels-and-locks-testing.appspot.com",
            messagingSenderId: "938816615552",
            appId: "1:938816615552:web:b6e1d0f231e9a8a751e265",
            measurementId: "G-K69JBR16JW"
        };
    }
    if (window.location.hostname === "localhost" || window.location.hostname === process.env.REACT_APP_DEV_HOST) {
        firebaseConfig.databaseURL = `http://${window.location.hostname}:9000?ns=${firebaseConfig.projectId}`;
    }
}
firebase.initializeApp(firebaseConfig);

firebase.analytics();

var db = firebase.firestore();
if ((window.location.hostname === "localhost" || window.location.hostname === process.env.REACT_APP_DEV_HOST) && !process.env.REACT_APP_DEV_MODE) {
    db.settings({
        host: `${window.location.hostname}:8080`,
        ssl: false
    });
}

export default firebase;
