import React from 'react';
import _ from 'lodash';
import { Box, Image, Text, Button } from '@chakra-ui/core';
import clsx from 'clsx';
import { useGameContext, useGameState } from '../context';
import { useI18n } from '../i18n';
import styles from './Chanukiah.module.css';

import chanukiahImg from '../content/chanukahShared/art/transition-screen-chanukiah-bg.png';
import flame from '../content/chanukahShared/img/animated-flame.webp';
import geltFront from '../content/chanukahShared/img/Gelt-front.PNG';
import geltBack from '../content/chanukahShared/img/Gelt-back.PNG';

const positions = [
    [995, 265],
    [1850, 325],
    [1670, 325],
    [1455, 325],
    [1250, 325],
    [730, 325],
    [520, 325],
    [315, 325],
    [135, 325],
];

function Gelt({initialPos, i, onDone}) {
    const [pos] = React.useState(() => initialPos + ((Math.random()-0.5)*(window.innerWidth/20)));
    const [time] = React.useState(() => 1 + Math.random() * 2);
    const [delay] = React.useState(() => i + Math.random() * 2);
    const [size] = React.useState(() => 5 + Math.random() * 5);
    const [rotate] = React.useState(() => Math.random() * 720 - 360);
    const [side] = React.useState(() => Math.random() < 0.5);
    // console.log(i, pos, time);
    React.useEffect(() => {
        setTimeout(onDone, (delay + time) * 1000);
    }, [onDone, delay, time]);
    return (
        <Image
            src={side ? geltFront : geltBack}
            className={styles.gelt}
            width={size+'vw'}
            left={pos + 'px'}
            animation={`${styles.geltFall} ${time}s linear ${delay}s both`}
            transform={`rotate(${rotate}deg)`}
        />
    );
}

function Chanukiah({data, enterClue}) {
    // const {map} = useGameContext();
    const { rtl } = useI18n();
    let { level } = useGameState();
    if (level === 'TRIAL_EXPIRED') {
        level = 2; // just over here, to show the correct transition
    }
    if (level === 'CERTIFICATE') {
        level = 11;
    }
    const imgRef = React.useRef(null);
    const [scale, setScale] = React.useState([0, 0]);
    const [animate, setAnimate] = React.useState(0);
    const avgScale = (scale[0] + scale[1]) / 2;

    const _naturalHeight = imgRef.current?.naturalHeight;
    const onLoad = React.useCallback(() => {
        if (!data) return; // no animation in preload
        const { naturalWidth, naturalHeight, clientWidth, clientHeight } = imgRef.current || {};
        const xScale = clientWidth / naturalWidth, yScale = clientHeight / naturalHeight;

        // Chrome has an issue with onLoad firing early before the image is fully loaded.
        // Force a rerender then to trigger onload again when it's actually loaded.
        if (!naturalWidth || !naturalHeight) {return setTimeout(() => setScale(s => s.slice()), 10)}

        setScale([xScale, yScale]);
        setTimeout(() => {
            setAnimate(1);
            if (level === 11) {
                // wait until all the gelt has finished falling
            } else if (level >= 9) {
                setTimeout(() => {
                    setAnimate(2);
                    setTimeout(enterClue, 7000);
                }, level===10 ? 100 : 4500);
            } else {
                setTimeout(enterClue, 6000);
            }
        }, 100)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, enterClue, _naturalHeight]);

    const [geltCount, setGeltCount] = React.useState(0);
    const onGeltDone = React.useCallback(() => {
        setGeltCount(gc => gc + 1);
    }, []);
    React.useEffect(() => {
        if (geltCount === 27) { // positions.length*3
            setTimeout(enterClue, 1000);
        }
    }, [geltCount, enterClue]);

    // console.log(animate, scale, avgScale, level, data);

    return (
        <Box
            pos="absolute"
            h="100%" w="100%"
            overflow="hidden"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={clsx(rtl ? styles.rtl : styles.ltr)}
        >
            <Box h="100%" w="auto" position="relative">
                <div>
                    {positions.slice(0, level).map((pos, i) => (
                        <Box key={i} className={clsx(styles.flameBox, avgScale && styles.ready)} left={pos[0]*scale[0]} top={pos[1]*scale[1]} transform={`scale(${avgScale})`}>
                            <Image src={flame} alt="Flame" className={clsx(styles.flame, (i < level - 1 || (i===level-1 && animate)) && styles.up)} />
                        </Box>
                    ))}
                </div>
                {level === 10 && data && (
                    <div>
                        {positions.map((pos, i) => {
                            let text = data.entering[i];
                            if (text[0] === '[') { // JSON array
                                text = JSON.parse(text);
                                pos = [text[0], text[1]];
                                text = text[2];
                            }
                            return (
                                <Box key={i} className={clsx(styles.clue10TextBox, avgScale && styles.ready)} left={pos[0] * scale[0]} top={pos[1] * scale[1]} transform={`scale(${avgScale})`}>
                                    <Text className={clsx(styles.clue10Text, animate === 2 && styles.shown)}>{text}</Text>
                                </Box>
                            );
                        })}
                    </div>
                )}
                <Image ref={imgRef} h="100%" src={chanukiahImg} alt="Chanukiah" onLoad={onLoad} zIndex={10} position="relative" />
                {bottomText()}
                {/* <Button pos="absolute" zIndex={50} top="50%" left="50%" onClick={enterClue}>Enter clue</Button> */}
            </Box>
            {level === 11 && animate && (
                positions.map((pos, i) => [0, 1, 2].map(j => (
                    <Gelt key={i+'.'+j} initialPos={pos[0]/imgRef.current.naturalWidth*(window.innerWidth*0.9)} i={j} onDone={onGeltDone} />
                )))
            )}
            {level === 11 && !data && ( // preload the gelt images
                <>
                    <img src={geltFront} alt="gelt" />
                    <img src={geltBack} alt="gelt" />
                </>
            )}
        </Box>
    );

    function bottomText() {
        let arr;
        if (!data) return null;
        if (level === 9) {
            arr = (data.entering.length === 3 ? data.entering : _.chunk(data.entering, 3));
        } else if (level === 10 && data.entering.length > positions.length) {
            arr = [JSON.parse(data.entering[positions.length])];
        }
        return arr?.map((word, i) => (
            <Text key={i}
                className={clsx(styles.clue9Text, animate === 2 && styles.shown, word[3] && styles.tallCover)}
                left={word[0] * scale[0]} top={word[1] * scale[1]}
                fontSize={Math.round((word[3]||64) * avgScale) + 'px'} fontFamily={null}
            >{word[2]}</Text>
        ));
    }
}

export default Chanukiah;
