import React from 'react';
import { Box, Image, Text } from '@chakra-ui/core';
import clsx from 'clsx';
import { useGameState } from '../context';
import styles from './Torah.module.css';

import scrollLeft from '../content/breisheetShared/img/crp-scroll-left.png';
import scrollRight from '../content/breisheetShared/img/crp-scroll-right.png';
import scrollMiddleBlank from '../content/breisheetShared/img/crp-scroll-middle-blank.png';
import scrollMiddleText from '../content/breisheetShared/img/crp-scroll-middle-blank.png';

function useForceRerender() {
    const [,s] = React.useState(0);
    return React.useCallback(() => s(Date.now()), []);
}

function Torah({ data, enterClue }) {
    console.log(data);
    let { level } = useGameState();
    if (level === 'TRIAL_EXPIRED') {
        level = 2; // just over here, to show the correct transition
    }
    if (level === 'CERTIFICATE') {
        level = 11;
    }

    const imgRef1 = React.useRef(null);
    const imgRef2 = React.useRef(null);
    const [scrollWidth, setScrollWidth] = React.useState(null);
    const forceRerender = useForceRerender();

    const onLoad = React.useCallback(() => {
        const { naturalWidth, clientWidth } = imgRef1.current || {};
        const xScale = clientWidth / naturalWidth;
        // console.log({imgRef1, naturalWidth, naturalHeight, clientWidth, clientHeight, xScale, yScale, sizeScale});
        // console.log({imgRef2, nw: imgRef2.current?.naturalWidth});

        // Chrome has an issue with onLoad firing early before the image is fully loaded.
        // Force a rerender then to trigger onload again when it's actually loaded.
        if (!naturalWidth || !imgRef2.current?.naturalWidth) { return setTimeout(forceRerender, 10) }

        setTimeout(enterClue, 6000);
        setScrollWidth(imgRef2.current.naturalWidth * xScale + 1);
    }, [enterClue, forceRerender]);

    return (
        <Box
            pos="absolute"
            h="100%" w="100%"
            overflow="hidden"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={styles.bg}
        >
            <Box h="97%" w="95%" position="relative" className={clsx(styles.scrollContainer)}>
                <Image className={clsx(styles.scrollLeft)} src={scrollLeft} alt="" onLoad={onLoad} ref={imgRef1} />
                <Box className={clsx(styles.scrollMiddleContainer)} width={scrollWidth}>
                    <Box className={clsx(styles.scrollMiddleScrollingContainer)}>
                        <Box className={clsx(styles.scrollMiddleTextContainer)}>
                            <Image className={clsx(styles.scrollMiddle)} src={scrollMiddleBlank} alt="" onLoad={onLoad} ref={imgRef2} />
                            {data?.entering && <Text className={clsx(styles.scrollMiddleText)} fontFamily={null}>
                                <span lang="he">{data.entering.he}</span>
                                <br/>
                                <span lang="en">{data.entering.en}</span>
                            </Text>}
                        </Box>
                        <Box className={clsx(styles.scrollMiddleTextContainer)}>
                            <Image className={clsx(styles.scrollMiddle)} src={scrollMiddleBlank} alt="" onLoad={onLoad} ref={imgRef2} />
                            {data?.exiting && <Text className={clsx(styles.scrollMiddleText)} fontFamily={null}>
                                <span lang="he">{data.exiting.he}</span>
                                <br/>
                                <span lang="en">{data.exiting.en}</span>
                            </Text>}
                        </Box>
                    </Box>
                </Box>
                <Image className={clsx(styles.scrollRight)} src={scrollRight} alt="" />
            </Box>
        </Box>
    )
}

export default Torah;
