import React from 'react';
import _ from 'lodash';
import { Box, Image, Button, Text } from '@chakra-ui/core';
import clsx from 'clsx';
import { useGameContext, useGameState } from '../context';

import lockTop from '../content/highHolidaysShared/img/lock-top.png';
import lockBottom from '../content/highHolidaysShared/img/lock-bottom.png';
import endBg from '../content/highHolidaysShared/img/end-bg.png';
import endMessage from '../content/highHolidaysShared/art/end-message.png';

import styles from './Calendar.module.css';


function Calendar({ data, enterClue }) {
    const { map: calendarData } = useGameContext(); // {label, startDay(0-6), days, totalLocks}
    let { level } = useGameState();
    if (level === 'TRIAL_EXPIRED') {
        level = 2; // just over here, to show the correct transition
    }
    if (level === 'CERTIFICATE') {
        level = 11;
    }
    // console.log(level);

    React.useEffect(() => {
        if (level < 2) return enterClue();

        if (level === 11) {
            // setTimeout(enterClue, 20000);
            return
        }

        setTimeout(enterClue, 8000);
    }, [enterClue, level]);

    if (level < 2) return null;

    const nextMonthDays = (7 - (calendarData.days + calendarData.startDay) % 7) % 7;

    return (<Box className={clsx(styles.container, level === 11 && styles.finalLevel)}>
        <Box h="100vh">
            <Text as="h3" textAlign="center" fontSize="3xl">{calendarData.label}</Text>
            <Box className={styles.calendar}>
                {!!calendarData.startDay && <Box gridColumn={'span ' + calendarData.startDay} className={clsx(styles.day, styles.outOfRangeDays)}></Box>}
                {_.range(calendarData.days).map(i => (
                    <Box key={i} className={clsx(styles.day, i < level-2 && styles.dayDone, i === level-2 && styles.dayActive)}>
                        <span>{i + 1}</span>
                        {i < calendarData.totalLocks && (
                            <span className={clsx(styles.lockContainer, i >= level-1 && styles.lockClosed)}>
                                <Image src={lockTop} />
                                <Image src={lockBottom} />
                            </span>
                        )}
                    </Box>
                ))}
                {!!nextMonthDays && <Box gridColumn={'span ' + nextMonthDays} className={clsx(styles.day, styles.outOfRangeDays)}></Box>}
            </Box>
        </Box>
        <Box h="100vh" pos="relative" bgImage={`url(${endBg})`} bgSize="cover">
            <Image
                src={endMessage}
                alt={'"Every act of forgiveness mends something broken." -Rabbi Jonathan Sacks'}
                maxH="90%" maxW="100%"
                m="auto"
                pos="absolute"
                top={0} bottom={0} left={0} right={0}
            />
            <Text as="h3" textAlign="center" fontSize="5xl" className={styles.endMessageTitle}>You've revealed the hidden message!</Text>
            <Button pos="absolute" bottom={5} left={0} right={0} m="auto" onClick={enterClue} variantColor="salmon">Continue</Button>
        </Box>
    </Box>)
}

export default Calendar;
