import React from 'react';
import { Box } from "@chakra-ui/core";
import { useI18n } from './i18n';


export default function PageContainer({children, noFlex=false}) {
    const {t, rtlClass} = useI18n();
    const flexProps = noFlex ? {} : {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    };
    return (
        <Box
            pos="absolute"
            h="100%" w="100%"
            overflow="auto"
            backgroundColor="darkBlue.500"
            color="white"
            dir={t('__dir')}
            className={rtlClass}
            {...flexProps}
        >
            {children}
        </Box>
    );
}
