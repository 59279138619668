import React from 'react';
import _ from 'lodash';
import { createI18n, I18nProvider, useI18n as useI18n_orig } from 'react-simple-i18n';
import rtlStyles from './rtl.module.css';

import en from './en';
import he from './he';

const langData = {en, he};

export function AppI18nProvider({lang='en', children}) {
    return (
        <I18nProvider i18n={createI18n(langData, {lang})}>
            {children}
        </I18nProvider>
    )
}

// utility to flip props for rtl
const propsToFlip = {
    left: 'right',
    marginLeft: 'marginRight',
    paddingLeft: 'paddingRight',
    leftIcon: 'rightIcon',
};
const _flip = (dir, props) => {
    let newProps = {...props};
    // flip
    if (dir === 'rtl') {
        _.forEach(propsToFlip, (rKey, lKey) => {
            if (_.has(props, lKey) || _.has(props, rKey)) { // avoid setting lots of undefined props
                newProps[rKey] = props[lKey];
                newProps[lKey] = props[rKey];
            }
        });
    }
    // replace <<left|right>> transforms with appropriate values (eg for icons)
    // example: 'chevron-<<left|right>>' (left for ltr, right for rtl)
    // applies to all properties
    _.forEach(newProps, (val, key) => {
        if (_.isString(val) && _.includes(val, '<')) {
            let toReplace = val.match(/<<(\w+)\|(\w+)>>/);
            if (toReplace) {
                newProps[key] = val.replace(toReplace[0], dir === 'ltr' ? toReplace[1] : toReplace[2]);
            }
        }
    });
    return newProps;
}

export function useI18n(scope) {
    const { t: _t, i18n } = useI18n_orig();
    const flip = (props) => _flip(_t('__dir'), props);
    const t = (key, ...args) => _t(scope ? `${scope}.${key}` : key, ...args);
    const rtl = _t('__dir') === 'rtl';
    const rtlClass = rtl ? rtlStyles.rtlContainer : '';
    return { t, i18n, rtl, flip, rtlClass };
}
