import React from 'react';
import _ from 'lodash';
import firebase from './firebase';
import { useAudio } from 'react-use';

// import junior from './content/challengeOneJunior';
// import regular from './content/challengeOneRegular';
// import advanced from './content/challengeOneAdvanced';

export const GlobalContext = React.createContext({meta: {paid: false}, trialExpired: true});
export const useGlobalContext = () => React.useContext(GlobalContext);

export const GameContext = React.createContext(null);
export const useGameContext = () => React.useContext(GameContext);

export const _gameSettings = {
    sound: {
        bg: true,
        fx: true,
        volume: 1
    },
    leaderboardOpen: false,
};
export const GameSettingsContext = React.createContext(_gameSettings);
export const useGameSettings = () => React.useContext(GameSettingsContext);

export const GameStateContext = React.createContext(null);
export const useGameState = () => React.useContext(GameStateContext);

export const GameActionsContext = React.createContext(null);
export const useGameActions = () => React.useContext(GameActionsContext);

export function useGameAudio(type, props) {
    const {sound} = useGameSettings();
    const [audio, , , ref] = useAudio({...props, volume: sound.volume * (props.volume||1), muted: !sound[type]});
    React.useEffect(() => {
        if (ref.current) {
            ref.current.volume = sound.volume * (props.volume || 1);
        }
    }, [sound.volume, props.volume, ref]);
    return [audio, ref];
}
function fadeAudio(audioEl, toVal) {
    return new Promise(function (resolve, reject) {
        var NUM_REPEATS = 10;
        var i = 0;
        var changeBy = (toVal - audioEl.volume) / NUM_REPEATS;
        var ival = setInterval(() => {
            try {
                audioEl.volume += changeBy;
            } catch (e) {
                return resolve();
            }
            if (++i === NUM_REPEATS) {
                clearInterval(ival);
                return resolve();
            }
        }, 1000 / NUM_REPEATS);
    });
}
export function useFadeAudio(volumeScale) {
    const { sound } = useGameSettings();
    return (audioRef, onOrOff, cb = _.noop) => {
        if (audioRef) {
            fadeAudio(audioRef, onOrOff ? sound.volume * volumeScale : 0).then(cb);
        }
    }
}

export function useConnectedUsers() {
    const { meta } = useGlobalContext();
    const [users, setUsers] = React.useState({});
    React.useEffect(() => {
        if (process.env.REACT_APP_DEV_MODE) return;
        const colRef = firebase.firestore().collection(`/gameState/${meta.id}/users/`);
        return colRef.where('active', '==', true).onSnapshot(querySnap => {
            let _users = {}
            querySnap.forEach(doc => {
                _users[doc.id] = doc.data();
            });
            setUsers(_users);
            // console.log('users updated', _users);
        });
    }, [meta.id]);
    return users;
}

export function useCollaborativeState(key, defaultValue, callback=_.noop) {
    const { meta } = useGlobalContext();
    const [_val, _setLocalVal] = React.useState({val: defaultValue, lastChanged: 0});
    const [didReceiveData, setDidReceiveData] = React.useState(false);
    // console.log('useCollaborativeState', key, _val);
    React.useEffect(() => {
        if (meta.features?.collaborative) {
            const docRef = firebase.firestore().doc(`/gameState/${meta.id}/synced/${key}`);
            const unsubscribe = docRef.onSnapshot(doc => {
                // console.log('updated data received', key, doc, doc.data());
                let data;
                if (doc.exists) {
                    data = doc.data();
                    const lastChanged = data.lastChanged?.toMillis();
                    if (lastChanged > _val.lastChanged) {
                        _setLocalVal({val: data.val, lastChanged});
                    }
                } else {
                    data = { val: defaultValue, lastChanged: firebase.firestore.FieldValue.serverTimestamp() };
                    docRef.set(data);
                }
                if (!didReceiveData) {
                    callback(data.val);
                    setDidReceiveData(true);
                }
            });
            return unsubscribe;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    if (meta.features?.collaborative) {
        const docRef = firebase.firestore().doc(`/gameState/${meta.id}/synced/${key}`);
        return [_val.val, (newVal, updateLocal=true) => {
            // console.log('update', key, newVal);
            if (updateLocal) {
                _setLocalVal({val: newVal, lastChanged: Date.now()});
            }
            return docRef.set({val: newVal, lastChanged: firebase.firestore.FieldValue.serverTimestamp()});
        }];
    }
    return [_val.val, (newVal) => {
        _setLocalVal({ val: newVal, lastChanged: Date.now() });
        return Promise.resolve();
    }];
}

export function useIsCollaborative() {
    const { meta } = useGlobalContext();
    const isCollaborative = meta.features?.collaborative;
    return !!isCollaborative;
}

export function useIsCompetitive() {
    const { meta } = useGlobalContext();
    const isCompetitive = meta.features?.competitive;
    return !!isCompetitive;
}

export function useWaitForPlayers(actionKey, callback, cleanupCb=_.noop) {
    // wait for all players in a collaborative game to confirm (or reject) an action
    const MAX_TIME_TO_WAIT = 15; // seconds after action initiated by which it will default to yes
    const uid = firebase.auth().currentUser.uid;
    const users = useConnectedUsers();

    const isCollaborative = useIsCollaborative();

    const [responseData, setResponseData] = useCollaborativeState(actionKey, {waiting: null, first: null, responses: {}});
    // const [isFirst, setIsFirst] = React.useState(false);
    const [countdownStarted, setCountdownStarted] = React.useState(null);
    const [intervalId, setIntervalId] = React.useState(null);
    const dataRef = React.useRef(responseData);
    // FIXME: not the best way to handle this
    // Need to handle the component unmounting after continuing
    // In that case remove their response from the log, and don't wait for them
    // If the first user exits, reassign first to someone else

    React.useEffect(() => {
        clearInterval(dataRef.current.intervalId);
        setIntervalId(null);
        setCountdownStarted(null);
        setResponseData({ waiting: null, first: null, responses: {} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionKey]);

    React.useEffect(() => {
        dataRef.current = {...responseData, intervalId};
        // console.log(actionKey, dataRef.current);

        if (_.size(users) > 0) {
            const { responses, waiting, first } = responseData;
            const allSubmitted = waiting?.length === 0; // _.size(responses) === _.size(users);
            const isApproved = _.every(responses);
            const timedOut = countdownStarted && Date.now() > countdownStarted + MAX_TIME_TO_WAIT * 1000;
            const isFirst = (first || (waiting||[])[0]) === uid;
            // console.log({isFirst, allSubmitted, isApproved, timedOut, intervalId, responses, waiting, first, sizeRes: _.size(responses)});

            if (allSubmitted || /*!isApproved ||*/ timedOut)  {
                // all responses were recorded (or timed out)
                clearInterval(intervalId);
                setIntervalId(null);
                setCountdownStarted(null);
                // tell one client to actually make the action, others just do local state cleanup
                if (isFirst) {
                    setResponseData({waiting: null, first: null, responses: {}});
                    dataRef.current = { waiting: null, first: null, responses: {}, intervalId: null };
                    callback(isApproved);
                }
                cleanupCb(isApproved);
            } else {
                // start the countdown
                if (!intervalId && _.size(responses) > 0) {
                    setIntervalId(setInterval(() => {
                        setCountdownStarted(countdown => (countdown || Date.now()) + 1);
                        // adjust it by 1 millisecond to force a rerender
                    }, 1000));
                }
            }
        }
    }, [callback, responseData, users, countdownStarted, intervalId, setResponseData, uid, cleanupCb]);

    React.useEffect(() => {
        return () => {
            const { responses, waiting, first, intervalId } = dataRef.current;
            // console.log(responses, waiting, first);

            if (intervalId) {
                clearInterval(intervalId);
            }
            if (_.size(responses)) {
                if (!first && waiting?.length === 1 && waiting[0] === uid) {
                    // PROBLEM!!!
                    // last one is unmounting, do the action immediately
                    const isApproved = _.every(responses);
                    callback(isApproved);
                    cleanupCb(isApproved);
                    setResponseData({ waiting: null, first: null, responses: {} }, false);
                } else {
                    setResponseData({
                        waiting: waiting && _.without(waiting, uid),
                        responses: responses, // _.omit(responses, uid),
                        first: null
                    }, false);
                    cleanupCb(null);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        const { responses, waiting, first } = dataRef.current;
        const uids = _.keys(users);
        // console.log({responses, waiting, first, uids});

        const newWaiting = waiting && _.intersection(waiting, uids);
        const firstHere = _.includes(uids, first);
        // console.log(newWaiting, firstHere, _.isEqual(waiting, newWaiting));

        if (!_.isEqual(waiting, newWaiting) || !firstHere) {
            setResponseData({
                waiting: newWaiting,
                responses: responses, // _.omit(responses, uid),
                first: firstHere ? first : null
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);

    // console.log(actionKey, responseData, countdownStarted, users, uid);

    const submitApproval = React.useCallback((approved=true) => {
        if (isCollaborative) {
            let { responses, waiting, first } = responseData;
            if (!waiting) {waiting = _.keys(users)}
            // console.log(approved, responses, waiting, first, uid);
            setResponseData({
                waiting: _.without(waiting, uid),
                responses: {...responses, [uid]: approved},
                first: first || uid
            });
        } else {
            callback(approved);
            cleanupCb(approved);
        }
    }, [callback, cleanupCb, isCollaborative, responseData, setResponseData, uid, users]);

    return [countdownStarted ? Math.round(MAX_TIME_TO_WAIT - (Date.now() - countdownStarted) / 1000) : null, submitApproval];
}
