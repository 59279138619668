import React from 'react';
import { Box, Button, Image, Text, IconButton, ButtonGroup, Link, SimpleGrid } from "@chakra-ui/core";
import { useGameContext, useGlobalContext, useGameState, useGameActions, useIsCompetitive } from './context';
import { formatTime } from './Timer';
import PageContainer from './PageContainer';
import { useI18n } from './i18n';
import logo from './logo.png';
import map from './content/challengeOneShared/art/map-transparent.png';
import styles from './EndCertificate.module.css';
import { FaListOl, FaFacebookSquare, FaInstagramSquare, FaTwitterSquare } from 'react-icons/fa';

import challengeOneLogo from './content/challengeOneShared/art/logo.png';
import challengeTwoLogo from './content/challengeTwoShared/art/logo.png';
import jerusalemLogo from './content/jerusalemShared/art/logo.png';
import tzfatLogo from './content/tzfatShared/art/logo.png';
import hhdLogo from './content/highHolidaysShared/art/logo-words.png';
import chanukahLogo from './content/chanukahShared/art/logo-en.png';
import purimLogo from './content/purimShared/art/logo.png';
import pesachLogo from './content/pesachShared/art/logo.png';

const links = {
    'israelquest-challenge-one': 'https://bagelsandlocks.com/congratulations/',
    'israelquest-jerusalem': 'https://bagelsandlocks.com/congratulations-2/',
    'holidayquest-hhd-unlocked': 'https://bagelsandlocks.com/congratulations-3/',
}

export default function EndCertificate() {
    const {t, flip} = useI18n('certificate');
    const { meta } = useGlobalContext();
    const gameContext = useGameContext();
    const gameState = useGameState();
    const isCompetitive = useIsCompetitive();
    const { settings: { toggleLeaderboardOpen } } = useGameActions();
    const exitLink = links[meta.game.game] || 'https://bagelsandlocks.com/congratulations/';
    return (
        <PageContainer>
            <Box
                h="98%"
                w="95%"
                // maxW="1200px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                // flexDirection="column"
                // overflow="auto"
                // border="10px solid black"
                p={5} m={5}
                // className={styles.introWrapper}
            >
                <Box display="flex" flexDirection="row" flex={1} maxH="98%">
                    <Box flex={3} textAlign="center">
                        <Box margin="auto" display="flex" flexDirection="row">
                            <Image src={logo} alt="Bagels and Locks Studios" className={styles.logo} m="auto" />
                            <Image src={gameContext.logo || map} alt={gameContext.name} className={styles.logo} m="auto" />
                        </Box>
                        <Text as="h3" fontSize="3xl" my={1}>{t('congratulations')}</Text>
                        <Text as="h2" fontSize="4xl" my={1}>{gameState.name}</Text>
                        <Text as="h3" fontSize="3xl" my={1}>{t('youCompleted')}</Text>
                        {isCompetitive && meta.parentData?.name && <Text as="h2" fontSize="4xl" my={1}>{meta.parentData?.name}</Text>}
                        <Text as="h2" fontSize="5xl" my={1}>{gameContext.name}</Text>
                        <Text as="h3" fontSize="3xl" my={1}>{t('finalTime', formatTime(gameState.timer))}</Text>
                        <Text as="p" fontSize="xl" my={5}>{gameContext.finalMessage || t('yourImpressiveSkillSet')}</Text>
                        <Text as="p" fontSize="xl" my={5}>{t('getReady')}</Text>
                        <Text as="p" fontSize="xl" my={5}>
                            {t('shareScreenshot')}
                            <ButtonGroup spacing={0} marginLeft={2}>
                                <IconButton as="a" icon={FaFacebookSquare} aria-label="Facebook" variant="ghost" variantColor="white" fontFamily="1em" href="https://www.facebook.com/Bagels-Locks-Studios-102486188119827" title="Bagels & Locks Studios" />
                                <IconButton as="a" icon={FaInstagramSquare} aria-label="Instagram" variant="ghost" variantColor="white" fontFamily="1em" href="https://instagram.com/bagels_and_locks_studios" title="@bagels_and_locks_studios" />
                                <IconButton as="a" icon={FaTwitterSquare} aria-label="Twitter" variant="ghost" variantColor="white" fontFamily="1em" href="https://twitter.com/BagelsLocks" title="@BagelsLocks" />
                            </ButtonGroup>
                        </Text>
                        <Box textAlign="center" my={2}>
                            <Button as="a" variantColor="salmon" variant="solid" marginY={2} marginX={2} href={exitLink}>
                                {t('exit')}
                            </Button>
                            {isCompetitive && <Button onClick={toggleLeaderboardOpen} variantColor="salmon" variant="solid" marginY={2} marginX={2} dir="ltr" {...flip({leftIcon: FaListOl})}>{t('seeLeaderboard')}</Button>}
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <Text as="h3" fontSize="3xl" my={1} textAlign="center">{t('checkOutOutOtherGames')}</Text>
                        <SimpleGrid minChildWidth="40%" spacing={4}>
                            <Link href="https://bagelsandlocks.com/#our-games"><Image maxH="18vh" src={challengeOneLogo} alt="IsraelQuest: Challenge One" /></Link>
                            <Link href="https://bagelsandlocks.com/#our-games"><Image maxH="18vh" src={challengeTwoLogo} alt="IsraelQuest: Challenge Two" /></Link>
                            <Link href="https://bagelsandlocks.com/#our-games"><Image maxH="18vh" src={jerusalemLogo} alt="IsraelQuest: The Jerusalem Secret" /></Link>
                            <Link href="https://bagelsandlocks.com/#our-games"><Image maxH="18vh" src={tzfatLogo} alt="IsraelQuest: The Kabbalah Code" /></Link>
                            <Link href="https://bagelsandlocks.com/#our-games"><Image maxH="18vh" src={hhdLogo} alt="HolidayQuest: High Holidays Unlocked" /></Link>
                            <Link href="https://bagelsandlocks.com/#our-games"><Image maxH="18vh" src={chanukahLogo} alt="HolidayQuest: Chanukah Lights" /></Link>
                            <Link href="https://bagelsandlocks.com/#our-games"><Image maxH="18vh" src={purimLogo} alt="HolidayQuest: A Megillah Mystery" /></Link>
                            <Link href="https://bagelsandlocks.com/#our-games"><Image maxH="18vh" src={pesachLogo} alt="HolidayQuest: The Missing Matzah" /></Link>
                        </SimpleGrid>
                    </Box>
                </Box>
            </Box>
        </PageContainer>
    )
}
