import _ from 'lodash';
import React from 'react';
import clsx from 'clsx';
import styles from './MainContent.module.css';

import { Box, useDisclosure } from "@chakra-ui/core";

import {useGameState} from './context';
import {useI18n} from './i18n';

import { Dialog, DialogBody, DialogHeader, DialogCloseButton } from './components/Dialog';


const CLOSED_WIDTH = 350, CLOSED_HEIGHT = 65;
const defaultPosition = isOpen => isOpen ? Dialog.position.center(0.5, 0.75) : {
    ...Dialog.position.centerWidth(CLOSED_WIDTH),
    ...Dialog.position.topQuarter(CLOSED_HEIGHT),
};
const toRndProps = state => ({
    position: {x: state.x, y: state.y},
    size: {width: state.width, height: state.height}
});

export default function MainContent({debug}) {
    const {t, flip} = useI18n();
    const state = useGameState();
    const { isOpen, onToggle } = useDisclosure(!!debug);
    const calculateState = (isOpen, oldState={}, newState={}) => {
        let dest = toRndProps(defaultPosition(isOpen));
        return _.merge(dest, oldState, newState, {
            isOpen,
            minHeight: isOpen ? 200 : 65,
            minWidth: isOpen ? 400 : 350,
            maxWidth: 1000,
            maxHeight: 700,
            enableResizing: isOpen ? undefined : false
        });
    };
    const [rndState, setRndState] = React.useState(calculateState(isOpen));
    // console.log(isOpen, rndState);
    React.useEffect(() => {
        if (isOpen !== rndState.isOpen) {
            let defaults = toRndProps(defaultPosition(rndState.isOpen));
            setRndState(calculateState(isOpen, _.isEqual(defaults.position, rndState.position) ? {} : {position: rndState.position}));
        }
    }, [isOpen, rndState]);

    const [isResizing, setIsResizing] = React.useState(false);

    const ClueContent = state.levelData.content;

    return (<>
        <main className={styles.mainContent}>
            <Dialog
                {...rndState}
                onDragStop={(e, d) => { setRndState(calculateState(isOpen, rndState, {position: {x: d.x, y: d.y} })) }}
                onResizeStart={() => setIsResizing(true)}
                onResizeStop = {
                    (e, direction, ref, delta, position) => {
                        setRndState(calculateState(isOpen, rndState, {
                            size: {
                                width: ref.style.width,
                                height: ref.style.height,
                            },
                            position,
                        }));
                        setIsResizing(false);
                    }
                }
                isOpen
                onClose={onToggle}
                boxProps={{ bg: 'rgba(255, 255, 255, 0.9)' }}
                className={clsx(styles.mainContainerDialog, isResizing && styles.resizing)}
            >
                <DialogHeader textAlign="center" bg="darkBlue.500" color="white">{state.levelData.location}</DialogHeader>
                {isOpen ? <DialogCloseButton variantColor="white" /> : <DialogCloseButton {...flip({ icon: 'arrow-<<forward|back>>' })} variantColor="green" color="white" size="lg" isRound variant="solid" title={t('clickToOpen')} />}
                <DialogBody>
                    {isOpen && <ClueContent />}
                </DialogBody>
            </Dialog>
        </main>
        <div className={styles.mainContainer}>
            <Box
                pos="absolute"
                h="100%" w="100%"
                overflow="hidden"
                bgImage={`url('${state.levelData.background}')`}
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundPosition="center"
            />
        </div>
    </>);
}
