import React from 'react';
import _ from 'lodash';
import firebase from './firebase';
import { useGlobalContext, useGameActions } from './context';
import { formatTime, calculateElapsed } from './Timer';
import PageContainer from './PageContainer';
import { useI18n } from './i18n';
import styles from './Leaderboard.module.css';

import { Box, Text, CloseButton } from "@chakra-ui/core";
import logo from './logo.png';

const db = firebase.firestore();

export function LeaderboardContent({state}) {
    const { meta } = useGlobalContext();
    const {t} = useI18n('leaderboard');

    let games = state?.children;
    games = games && _.mapValues(games, game => ({
        ...game,
        time: calculateElapsed(game.timer) || null,
        levelRank: game.level === -1 ? 20 : game.level === 'INTRO' ? 0 : game.level === 'CERTIFICATE' ? -20 : -game.level,
        levelLabel: game.level === -1 ? t('waiting') : game.level === 'CERTIFICATE' ? t('finished') : game.level === 'INTRO' ? t('intro') : game.level,
    }));
    const gameOrder = _(games).toPairs().sortBy('1.levelRank', '1.time', '1.name').map('0').value();
    // console.log(state, games, gameOrder);

    const [, _setrfrsh] = React.useState(0);
    React.useEffect(() => {
        const ival = setInterval(() => {
            _setrfrsh(n => n + 1);
        }, 500);
        return () => clearTimeout(ival);
    }, []);

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>{t('rank')}</th>
                    <th>{t('team')}</th>
                    <th>{t('level')}</th>
                    <th>{t('time')}</th>
                </tr>
            </thead>
            <tbody>
                {gameOrder.map((id, rank) => (
                    <tr key={id} className={id === meta.id ? styles.myRow : null}>
                        <td>{rank + 1}</td>
                        <th>{games[id].name}</th>
                        <td>{games[id].levelLabel}</td>
                        <td>{formatTime(games[id].timer)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

function Leaderboard() {
    const { meta, versions } = useGlobalContext();
    const { settings: { toggleLeaderboardOpen } } = useGameActions();

    const [state, setState] = React.useState(null);
    React.useEffect(() => {
        if (meta.features?.competitive) {
            const masterId = meta.parent || meta.id;
            return db.collection('gameState').doc(masterId).onSnapshot((doc) => {
                const data = doc.data();
                // console.log(data);
                setState(data);
            });
        }
    }, [meta]);

    return (
        <PageContainer noFlex>
            <Box
                // h="80%"
                w="70%"
                p={5} my={15} mx="auto"
                textAlign="center"
                position="relative"
            // className={styles.introWrapper}
            >
                <CloseButton size="lg" onClick={toggleLeaderboardOpen} position="fixed" top={5} right={5} />
                <Box w="50%" mx="auto" my={5}><img src={logo} alt="Bagels and Locks Studios" /></Box>
                <Text as="h1" fontSize="4xl" my={5}>{versions.regular}</Text>
                <Text as="h2" fontSize="2xl" my={3}>
                    {state?.name}
                </Text>
                <LeaderboardContent state={state} />
            </Box>
        </PageContainer>
    )
}

export default Leaderboard;
