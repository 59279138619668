import _ from 'lodash';
import React from 'react';
import { Box, ButtonGroup, IconButton } from '@chakra-ui/core';
import { useI18n } from '../i18n';
import styles from './index.module.css';

import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFView({url}) {
    const { t, flip } = useI18n('backpack');
    const [numPages, setNumPages] = React.useState(0);
    const onDocumentLoadSuccess = (pdf) => {
        console.log(pdf);
        setNumPages(pdf.numPages);
    }
    const [scale, setScale] = React.useState(1);
    const MIN_SCALE = 0.4, MAX_SCALE = 2;
    return (
        <Box p={5} bg="gray.500" className={styles.pdfContainer} dir="ltr">
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                {_.times(numPages, i => (
                    <Page key={i} pageNumber={i + 1} scale={scale} className={styles.pdfPage} />
                ))}
            </Document>
            <Box position="absolute" top={16} {...flip({right:8})}>
                <ButtonGroup spacing={1}>
                    <IconButton icon="minus" size="sm" aria-label={t('zoomOut')} title={t('zoomOut')} onClick={() => setScale(s => Math.max(s - 0.2, MIN_SCALE))} isDisabled={scale === MIN_SCALE} />
                    {/* <IconButton icon="search" size="sm" aria-label="Reset zoom" title="Reset zoom" onClick={() => setScale(1)} /> */}
                    <IconButton icon="add" size="sm" aria-label={t('zoomIn')} title={t('zoomIn')} onClick={() => setScale(s => Math.min(s + 0.2, MAX_SCALE))} isDisabled={scale === MAX_SCALE} />
                </ButtonGroup>
            </Box>
        </Box>
    );
}
