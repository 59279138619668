import React from 'react';
import clsx from 'clsx';
import styles from './Footer.module.css';
import logo from './logo_plain.png';

import { IconButton, Tooltip } from "@chakra-ui/core";
import { FaBriefcase, FaMobileAlt, FaEdit, FaQuestionCircle, FaCog } from 'react-icons/fa';
// import { GiBackpack } from 'react-icons/gi';

import { useGameContext, useGameState, useGameActions } from './context';
import { useI18n } from './i18n';

import Hints from './Hints';
import Comms from './Comms';
import GameSettings from './GameSettings';
import Timer from './Timer';


const FooterButton = React.forwardRef(({icon, label, onClick, color}, ref) => {
    return (
        <Tooltip hasArrow placement="top" label={label}>
            <span>
                <IconButton
                    ref={ref}
                    isRound
                    variantColor={color || "salmon"}
                    size="lg"
                    fontSize="1.8rem"
                    aria-label={label}
                    icon={icon}
                    onClick={onClick}
                />
            </span>
        </Tooltip>
    );
});

export default function Footer({ toggleBackpack, toggleNotes, isNotesFresh, numClues=10 }) {
    const {t, rtl} = useI18n('footer');
    const { name: gameTitle } = useGameContext();
    const state = useGameState();
    const actions = useGameActions();
    return (
        <footer className={clsx(styles.footer, rtl && styles.rtl)}>
            <div className={clsx(styles.footerItem, styles.footerButtons)}>
                <Comms
                    // key={state.level}
                    answerType={state.levelData.answerType}
                    answerState={state.answer}
                    button={<FooterButton icon={FaMobileAlt} label={t('labelCommunications')} />}
                />
                <FooterButton icon={FaBriefcase} label={t('labelBackpack')} onClick={toggleBackpack} />
                <FooterButton icon={FaEdit} label={t('labelNotes')} onClick={toggleNotes} color={isNotesFresh && 'green'} />
                <Hints
                    hints={state.levelData.hints}
                    getHint={actions.getHint}
                    button={
                        <FooterButton icon={FaQuestionCircle} label={t('labelHint')} />
                    }
                />
                <GameSettings actions={actions} button={<FooterButton icon={FaCog} label={t('labelSettings')} />} />
            </div>
            <div className={clsx(styles.footerItem, styles.footerLocation)}>
                <div className={styles.footerLocationSubtitle}>{t('currentLocation')}</div>
                <h3>{state.levelData.location}</h3>
                <h4 className={styles.footerClueCounter}>
                    {t('clueX', `${state.level}/${numClues}`)}
                </h4>
            </div>
            <div className={clsx(styles.footerItem, styles.footerLogo)}>
                <div className={styles.footerLogoPiece}>
                    <img src={logo} alt="Logo" />
                </div>
                <div className={styles.footerLogoPiece}>
                    <h2 className={styles.footerTitle}>{gameTitle}</h2>
                    <Timer />
                </div>
            </div>
        </footer>
    );
}
