import React from 'react';
import { Box } from '@chakra-ui/core';
import clsx from 'clsx';
import { useGameState } from '../context';
import styles from './Sefirot.module.css';

import SvgTreeOfLifeRef, {colors} from '../content/tzfatShared/SvgTreeOfLife';


function Sefirot({data, enterClue}) {
    let { level } = useGameState();
    if (level === 'TRIAL_EXPIRED') {
        level = 2; // just over here, to show the correct transition
    }
    if (level === 'CERTIFICATE') {
        level = 11;
    }

    const imgRef = React.useRef(null);
    const [pos, setPos] = React.useState([-1000, -1000]);
    const [size, setSize] = React.useState(0);
    const [didCompleteFill, setCompletedFill] = React.useState(false);
    const START_SIZE = 20, END_SIZE = 80;

    React.useEffect(() => {
        const { clientWidth, clientHeight } = imgRef.current || {};
        const naturalHeight = imgRef.current.viewBox.baseVal.height;
        const naturalWidth = imgRef.current.viewBox.baseVal.width;
        const xScale = clientWidth / naturalWidth, yScale = clientHeight / naturalHeight;
        const sizeScale = (xScale + yScale) / 2;
        // console.log({imgRef, naturalWidth, naturalHeight, clientWidth, clientHeight, xScale, yScale, sizeScale});
        // console.log({data, pos, size});

        // data is null on prerender
        if (!data) return;

        const startPos = data.exiting ? [data.exiting[0] * xScale, data.exiting[1] * yScale] : null;
        const endPos = data.entering ? [data.entering[0] * xScale, data.entering[1] * yScale] : null;

        if (startPos) {
            setPos(startPos);
            setSize(START_SIZE * sizeScale);
            // grow immediately
            setTimeout(() => {
                setSize(END_SIZE * sizeScale);
                // if continuing, remove the dot, then re-add it with the new color
                setTimeout(() => {
                    setSize(0);
                    setCompletedFill(true);

                    if (endPos) {
                        setTimeout(() => {
                            setSize(START_SIZE * sizeScale);
                            setTimeout(() => {
                                setPos(endPos);
                                setTimeout(enterClue, 6000);
                            }, 100);
                        }, 100);
                    } else {
                        setTimeout(enterClue, 6000);
                    }
                }, 4500);

            }, 1000);
        } else { // entering level 1
            setSize(START_SIZE * sizeScale);
            setPos(endPos);
            setTimeout(enterClue, 3000);
        }
    }, [data, enterClue, level]);

    return (
        <Box
            pos="absolute"
            h="100%" w="100%"
            overflow="hidden"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <Box h="100%" w="auto" position="relative">
                <SvgTreeOfLifeRef ref={imgRef} height="100%" level={didCompleteFill ? level : level-1} pulse={didCompleteFill && level===11} />
                <Box
                    className={clsx(styles.pulser, size && styles.ready)}
                    left={pos[0]} top={pos[1]}
                    w={size+'px'} h={size+'px'}
                    bg={colors[didCompleteFill ? level-1 : level-2]}
                />
            </Box>
        </Box>
    )
}

export default Sefirot;
