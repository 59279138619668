import React from 'react';
import _ from 'lodash';

import { AppI18nProvider } from './i18n';
import GameController from './GameController';
import SplashScreen from './SplashScreen';
import Dashboard from './Dashboard';
import { GlobalContext } from './context';
import firebase from './firebase';

import { deserialize as _deserialize } from './serialize';

import { FaBriefcase, FaMobileAlt, FaEdit, FaQuestionCircle } from 'react-icons/fa';
import { Box, Text, Image } from '@chakra-ui/core';
import ImageWithAltText from './components/ImageWithAltText';
import ImageWithRolloverZoom from './components/ImageWithRolloverZoom';

let devClientBasedServer;
if (process.env.REACT_APP_DEV_MODE) {
    devClientBasedServer = require('./devClientBasedServer');
}

const deserializeComponents = { FaBriefcase, FaMobileAlt, FaEdit, FaQuestionCircle, Box, Text, Image, ImageWithAltText, ImageWithRolloverZoom }
const deserializeToComponent = data => {
    if (typeof data === 'string') {
        const content = _deserialize(data, { components: deserializeComponents });
        return () => content;
    }
    return data;
}

const searchParams = new URLSearchParams(window.location.search);
let gameId = searchParams.get('gameId');
let trialGame = searchParams.get('trial');

const fetchFromServer = async (action, data) => {
    if (process.env.REACT_APP_DEV_MODE) {
        return devClientBasedServer.updateGame(action, data);
    }
    const f = await fetch('/updateGame', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ gameId, trialGame, action, data })
    });
    if (f.ok) {
        return f.json();
    } else {
        const res = await f.json();
        return Promise.reject([f.status, res]);
    }
}

const serverFetch = (onError) => ({
    increaseLevel: () => fetchFromServer('increaseLevel').catch(e => {
        onError(e)
        if (e[0] === 402) {
            return e[1].data;
        }
        return { nextLevelData: null, nextLevel: null };
    }).then(data => {
        if (data.nextLevelData?.content) {
            data.nextLevelData.content = deserializeToComponent(data.nextLevelData.content);
        }
        return data;
    }),
    getHint: () => fetchFromServer('getHint').then(data => {
        data = deserializeToComponent(data);
        return data;
    }).catch(onError),
    submitAnswer: (answer) => fetchFromServer('submitAnswer', answer).catch(onError),
    setNotes: (notes) => fetchFromServer('setNotes', notes).catch(onError),
    resetAnswer: () => fetchFromServer('resetAnswer').catch(onError),
    switchGameVersion: (newVersion) => fetchFromServer('switchGameVersion', newVersion).catch(onError).then(data => {
        data.gameObject.Intro = deserializeToComponent(data.gameObject.Intro);
        if (data.levelData) {
            data.levelData.content = deserializeToComponent(data.levelData.content);
        }
        return data;
    }),
    setName: (name) => fetchFromServer('setName', name).catch(onError),
    configureMeta: (data) => fetchFromServer('configureMeta', data).catch(onError),
})

const makeClientDataManager = (globalData, _onError) => {
    const onError = e => { // tap the error
        _onError(e);
        if (e[0] === 399) // reload error is not a real error
            return e[1];
        // otherwise propagate the error
        return Promise.reject(e);
    }
    const deserializeGameState = gameState => {
        if (gameState.gameObject) {
            gameState.gameObject.Intro = deserializeToComponent(gameState.gameObject.Intro);
        }
        if (gameState.levelData) {
            gameState.levelData.content = deserializeToComponent(gameState.levelData.content);
            gameState.levelData.hints = gameState.levelData.hints.map(hint => deserializeToComponent(hint));
        }
        return gameState;
    }
    const forceReload = () => _onError([399, null]); // fake error to force reload of global data
    if (globalData?.gameState) {
        return {
            initialState: deserializeGameState(globalData.gameState),
            fetchData: serverFetch(onError),
            deserializeGameState,
            forceReload
        };
    }
    const initialState = {
        gameObject: null,
        level: 'INTRO',
        levelData: null,
        notes: '',
        answer: null,
        name: globalData?.meta.name,
        timer: {
            started: null
        }
    }
    return { initialState, fetchData: serverFetch(onError), deserializeGameState, forceReload };
}

async function fetchGlobalData() {
    if (process.env.REACT_APP_DEV_MODE) {
        return devClientBasedServer.getGlobalGame();
    }
    const userId = firebase.auth().currentUser?.uid;
    // console.log(userId);

    const f = await fetch('/getGlobalGame', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ gameId, trialGame, userId })
    });
    if (f.ok) {
        const res = await f.json();
        if (!gameId) {
            gameId = res.meta.id;
        }
        return res;
    } else {
        const res = await f.json();
        return Promise.reject(res);
    }
}

// build presence using Firebase Realtime Database and a function to connect it to Firestore
// https://firebase.google.com/docs/firestore/solutions/presence
firebase.auth().onAuthStateChanged(function (user) {
    if (user && gameId) {
        // console.log(user.uid, gameId);

        const uid = user.uid;
        const userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
        const userStatusFirestoreRef = firebase.firestore().doc(`/gameState/${gameId}/users/${uid}`);

        const isOfflineForDatabase = {
            state: 'offline',
            lastChanged: firebase.database.ServerValue.TIMESTAMP,
            gameId,
        };
        const isOnlineForDatabase = {
            state: 'online',
            lastChanged: firebase.database.ServerValue.TIMESTAMP,
            gameId,
        };

        const isOfflineForFirestore = {
            active: false,
            lastChanged: firebase.firestore.FieldValue.serverTimestamp(),
        };
        const isOnlineForFirestore = {
            active: true,
            lastChanged: firebase.firestore.FieldValue.serverTimestamp(),
        };


        firebase.database().ref('.info/connected').on('value', function (snapshot) {
            if (!snapshot.val()) {
                userStatusFirestoreRef.set(isOfflineForFirestore);
                return;
            };

            // If we are currently connected, then use the 'onDisconnect()'
            // method to add a set which will only trigger once this
            // client has disconnected by closing the app,
            // losing internet, or any other means.
            userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
                // The promise returned from .onDisconnect().set() will
                // resolve as soon as the server acknowledges the onDisconnect()
                // request, NOT once we've actually disconnected:
                // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                // We can now safely set ourselves as 'online' knowing that the
                // server will mark us as offline once we lose connection.
                // console.log('setting online');
                userStatusDatabaseRef.set(isOnlineForDatabase);
                userStatusFirestoreRef.set(isOnlineForFirestore);
            });
        });
    }
});

const db = firebase.firestore();

const DEBUG = false;
function GlobalController() {
    const [globalData, setGlobalData] = React.useState(null);
    const [isLoadingSaved, setIsLoadingSaved] = React.useState(null);
    const loadGlobalData = (isRefetching = false) => fetchGlobalData().then(data => {
        if (DEBUG) {
            data.meta.paid = true;
            // data.trialExpired = true;
        }
        if (data.gameState && !data.gameState.name) {
            data.gameState.name = data.meta.name;
        }
        setGlobalData(data);
        if (!isRefetching && !process.env.REACT_APP_DEV_MODE) {
            setIsLoadingSaved(!!data.gameState);
        }
        document.title = data.versions.regular ? data.versions.regular + ' -- Bagels and Locks Studios' : 'Bagels and Locks Studios';
        if (!data.meta.paid) {
            trialGame = data.meta.game.game;
        }
    }).catch(e => {
        if (e.error === 'game full') {
            setGlobalData({ meta: { id: gameId, paid: false, gameFull: true } });
            // disconnect so we aren't taking up space in the user list
            firebase.database().goOffline();
        } else if (e.error === 'bad code') {
            setGlobalData({ meta: { id: gameId, paid: false }, trialExpired: true });
        } else {
            console.error(e);
        }
    });
    React.useEffect(() => {
        // create anonymous user to authorize for Firestore access
        firebase.auth().signInAnonymously().catch(function (error) {
            // Handle Errors here.
            console.log(error);
        }).then(loadGlobalData);
    }, []);
    const dataManager = makeClientDataManager(globalData, (e) => {
        console.error('dataManager error', e);
        const [status, res] = e;
        if (status === 402 && !DEBUG) {
            setGlobalData(gd => ({ ...gd, trialExpired: true }));
        } else if (status === 399) {
            // reload load game
            loadGlobalData(true);
        }
        else {
            console.error(res);
        }
    });

    console.log(globalData);

    if (!globalData) return null;

    const resumeGame = () => {
        setIsLoadingSaved(false);
        if (!globalData.gameState.timer?.started && !globalData.gameState.timer?.finished) {
            const now = Date.now();
            setGlobalData(gd => _.merge({}, gd, { gameState: { timer: { started: now } } }));
            db.collection('gameState').doc(globalData.meta.id).update({
                'timer.started': now
            });
        }
    }

    return (
        <AppI18nProvider lang={globalData.lang}>
            <GlobalContext.Provider value={_.pick(globalData, 'meta', 'versions', 'lang', 'trialExpired')}>
                {globalData.meta.children ? (
                    <Dashboard configureMeta={dataManager.fetchData.configureMeta} />
                ) : (isLoadingSaved || !globalData.versions) ? (
                    <SplashScreen onContinue={resumeGame} name={globalData.gameState?.name} version={globalData.gameState?.gameObject?.name || globalData.versions?.regular} />
                ) : (
                    <GameController dataManager={dataManager} />
                )}
            </GlobalContext.Provider>
        </AppI18nProvider>
    )
}

export default GlobalController;
