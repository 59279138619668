import React from 'react';
import _ from 'lodash';
import {
    Box,
    Text,
    Button,
    Select,
    Input,
    FormControl,
    FormLabel,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
} from "@chakra-ui/core";
import PageContainer from './PageContainer';
import { useGlobalContext, useIsCollaborative, useIsCompetitive, useCollaborativeState, useWaitForPlayers } from './context';
import firebase from './firebase';
import { useI18n } from './i18n';
import logo from './logo.png';

function InfoHover({ children }) {
    const { flip, rtlClass } = useI18n();
    return (
        <Popover trigger="hover" usePortal>
            <PopoverTrigger>
                <Icon name="info-outline" color="darkBrown.500" position="absolute" top={0} {...flip({ right: 0 })} zIndex={50} />
            </PopoverTrigger>
            <PopoverContent zIndex={60} className={rtlClass}>
                <PopoverArrow />
                <PopoverBody>
                    {children}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

function VersionInfoHover({ versions }) {
    const { t } = useI18n('versionSelector');
    return (
        <InfoHover>
            <Text as="p" my={2}>{t('hoverInfoJunior', versions.junior)}</Text>
            <Text as="p" my={2}>{t('hoverInfoStandard', versions.regular)}</Text>
            <Text as="p" my={2}>{t('hoverInfoPro', versions.pro)}</Text>
        </InfoHover>
    )
}

function NameInfoHover() {
    const { t } = useI18n('versionSelector');
    return (
        <InfoHover>
            <Text as="p" my={2}>{t('hoverInfoName')}</Text>
        </InfoHover>
    )
}

function PlayerNameInfoHover() {
    const { t } = useI18n('versionSelector');
    return (
        <InfoHover>
            <Text as="p" my={2}>{t('hoverInfoPlayerName')}</Text>
        </InfoHover>
    )
}

export default function VersionSelector({ setVersion, setName, forceReload }) {
    const uid = firebase.auth().currentUser.uid;
    const { t } = useI18n('versionSelector');
    const { versions, meta } = useGlobalContext();
    const isCollaborative = useIsCollaborative();
    const isCompetitive = useIsCompetitive();
    const [_game, _setGame] = useCollaborativeState('game', '');
    const [_version, _setVersion] = useCollaborativeState('version', meta.game?.version || 'regular');
    const [_name, _setName] = useCollaborativeState('teamName', meta.name);
    const [_players, _setPlayers] = useCollaborativeState('playerIds', {}, players => {
        _setPlayerName(players[uid] || '');
    });
    const [_playerName, _setPlayerName] = React.useState('');

    // versions of the specific game (if not variable, this is the regular case)
    const gameVersions = meta.game.game.includes('variable') ? versions[_game]?.versions : versions;
    const canSubmit = !!(meta.game.game.includes('variable') ? _game && _version : _version);

    const updatePlayers = () => {
        _setPlayers({ ..._players, [uid]: _playerName });
    }

    const [submitCollabCountdown, submitCollab] = useWaitForPlayers('versionSelector', React.useCallback(approved => {
        if (approved) {
            setName(_name).then(() => {
                return setVersion(_game ? {
                    game: _game,
                    version: _version
                } : _version);
            }).catch(err => {
                // console.log(err);
                if (err[0] === 399) {
                    // all good -- setting version and reloading
                } else {
                    console.error(err);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_name, _version, _game]), (approved) => {
        if (!approved) setDidSubmit(false);
        if (approved && _game) {
            setTimeout(forceReload, 5 * 1000); // reload global data after 5 seconds, to give enough time for it to update
        }
    });

    const [didSubmit, setDidSubmit] = React.useState(false);
    const onContinue = () => {
        setDidSubmit(true);
        submitCollab(true);
    }

    React.useEffect(() => {
        firebase.analytics().setCurrentScreen('version-selector');
        firebase.analytics().logEvent('screen_view');
    }, []);

    return (
        <PageContainer>
            <Box
                w="70%"
                p={5} my={15} mx="auto"
                textAlign="center"
            >
                <Box w="50%" mx="auto" my={5}><img src={logo} alt="Bagels and Locks Studios" /></Box>

                <Text as="h1" fontSize="5xl" my={5}>{versions.regular}</Text>

                {isCompetitive && meta.parentData?.name && <Text as="h2" fontSize="4xl" my={2}>{meta.parentData?.name}</Text>}

                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" my={5}>
                    <Text as="h2" fontSize="2xl" my={3}>
                        {t('beforeYouBegin')}
                    </Text>

                    <FormControl display="flex" flexDirection="row" position="relative" w="100%" maxW="500px" my={1}>
                        <FormLabel htmlFor="name" minW="75px">{isCollaborative ? t('teamName') : t('name')}</FormLabel>
                        <Input name="name" value={_name} onChange={e => { _setName(e.target.value) }} isDisabled={isCompetitive && !meta.parentData?.canEditName} bg="lightBrown.500" color="gray.800" borderColor="initial" />
                        <NameInfoHover />
                    </FormControl>
                    {isCollaborative && (
                        <FormControl display="flex" flexDirection="row" position="relative" w="100%" maxW="500px" my={1}>
                            <FormLabel htmlFor="player-name" minW="75px">{t('playerName')}</FormLabel>
                            <Input name="player-name" value={_playerName} onChange={e => { _setPlayerName(e.target.value) }} onBlur={updatePlayers} bg="lightBrown.500" color="gray.800" borderColor="initial" />
                            <PlayerNameInfoHover />
                        </FormControl>
                    )}
                    {meta.game.game.includes('variable') && <FormControl display="flex" flexDirection="row" position="relative" w="100%" maxW="500px" my={1}>
                        <FormLabel htmlFor="version" minW="75px">{t('game')}</FormLabel>
                        <Select name="game" value={_game} onChange={e => { _setGame(e.target.value) }} isDisabled={isCompetitive} bg="lightBrown.500" color="gray.800" borderColor="initial" >
                            <option value="" disabled>{t('selectGame')}</option>
                            {_.map(versions, (gameVersions, game) => (
                                <option key={game} value={game}>{gameVersions.versions.regular}</option>
                            ))}
                        </Select>
                    </FormControl>}
                    <FormControl display="flex" flexDirection="row" position="relative" w="100%" maxW="500px" my={1}>
                        <FormLabel htmlFor="version" minW="75px">{t('mission')}</FormLabel>
                        <Select name="version" value={_version} onChange={e => { _setVersion(e.target.value) }} isDisabled={isCompetitive || !gameVersions} bg="lightBrown.500" color="gray.800" borderColor="initial" >
                            {_.map(gameVersions, (name, id) => (
                                <option key={id} value={id}>{name}</option>
                            ))}
                        </Select>
                        {gameVersions && <VersionInfoHover versions={gameVersions} />}
                    </FormControl>
                    {isCollaborative && (
                        <Text as="p" my={2}>
                            {t('players') + ' '}
                            {_.values(_players).join(', ')}
                        </Text>
                    )}

                    {submitCollabCountdown && !didSubmit ? (
                        <Box my={5}>
                            <Button onClick={() => submitCollab(false)} mx={3} size="lg" variantColor="darkBrown">
                                {t('notReady')}
                            </Button>
                            <Button onClick={onContinue} mx={3} size="lg" variantColor="salmon" isDisabled={didSubmit || !canSubmit} isLoading={didSubmit && !submitCollabCountdown} loadingText={t('begin')}>
                                {t('begin')} ({submitCollabCountdown})
                            </Button>
                        </Box>
                    ) : (
                        <Button onClick={onContinue} size="lg" my={5} variantColor="salmon" isDisabled={didSubmit || !canSubmit} isLoading={didSubmit && !submitCollabCountdown} loadingText={t('begin')}>
                            {t('begin')}
                            {submitCollabCountdown && ` (${submitCollabCountdown})`}
                        </Button>
                    )}
                </Box>
                {!isCompetitive && <Box my={5}>
                    <Text as="p" my={5}>{t('dontWorryChangeLevel')}</Text>
                </Box>}
            </Box>
        </PageContainer>
    )
}
