import React from 'react';
import styles from './GameContainer.module.css';
import clsx from 'clsx';

import firebase from './firebase';
import { useGameState, useGameActions, useGameContext, useGameSettings, useGlobalContext, useIsCollaborative } from './context';
import { useI18n } from './i18n';
import { useDisclosure } from "@chakra-ui/core";

import Footer from './Footer';
import MainContent from './MainContent';
import Backpack from './Backpack';
import Notes from './Notes';

import SplashScreen from './SplashScreen';
import Leaderboard from './Leaderboard';

import EndCertificate from './EndCertificate';
import Intro from './Intro';

import transitionScreens from './transitions';

function GameContainer({debug}) {
    const state = useGameState();
    const actions = useGameActions();
    const { transition='map', name: gameName } = useGameContext();
    const isCollaborative = useIsCollaborative()
    const { isOpen: isBackpackOpen, onToggle: toggleBackpack } = useDisclosure(false);
    const { isOpen: isNotesOpen, onToggle: toggleNotes } = useDisclosure(isCollaborative);
    const {leaderboardOpen} = useGameSettings();
    const { trialExpired } = useGlobalContext();
    const {t, rtlClass} = useI18n();

    const [oldNotes, setOldNotes] = React.useState(state.notes);
    React.useEffect(() => {
        if (isNotesOpen) {
            setOldNotes(state.notes);
        }
    }, [isNotesOpen, state.notes]);

    React.useEffect(() => {
        firebase.analytics().setCurrentScreen(gameName + '/' + (state.level.toString().toLowerCase()));
        firebase.analytics().logEvent('screen_view');
        firebase.analytics().logEvent('level_up', {level: state.level});
    }, [gameName, state.level]);


    // screen to render
    let content;

    if (trialExpired) {
        content = <SplashScreen name={state.name} version={state.gameObject?.name} />
    } else if (leaderboardOpen) {
        content = <Leaderboard />;
    } else if (state.level === 'INTRO') {
        content = <Intro nextLevel={actions.increaseLevel} />;
    } else if (state.level === 'CERTIFICATE') {
        content = <EndCertificate />;
    } else {
        content = (
            <div className={clsx(styles.gameContainer, rtlClass)} dir={t('__dir')}>
                <MainContent debug={debug} />
                <Backpack isOpen={isBackpackOpen} toggle={toggleBackpack} />
                <Notes isOpen={isNotesOpen} toggle={toggleNotes} />
                <Footer {...{ toggleBackpack, toggleNotes }} isNotesFresh={oldNotes !== state.notes} numClues={state.gameObject.numClues} />
            </div>
        );
    }

    const TransitionScreen = transitionScreens[transition];

    // render transition screen, with content as secondary preload content
    if (state.mapData) {
    // if (state.mapData||true) {
    //     actions.mapToClue=()=>{}
        return (
            <div className={styles.preloadContainer}>
                <TransitionScreen data={state.mapData} enterClue={actions.mapToClue} />
                {content}
            </div>
        );
    } else {
        // render content, with transition screen as secondary preload content
        return (
            <div className={styles.preloadContainer}>
                {content}
                <TransitionScreen data={null} enterClue={() => {}} />
            </div>
        );
    }

}

export default GameContainer;
