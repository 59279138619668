import React from 'react';
import { Box, Text, Button } from "@chakra-ui/core";

const seferNames = {
    'parshaquest-breisheet': 'Sefer Breisheet',
    'parshaquest-breisheet-transliterated': 'Sefer Breisheet',
    'parshaquest-breisheet-translated': 'the Book of Genesis',
    'parshaquest-shmot': 'Sefer Shmot',
    'parshaquest-vayikra': 'Sefer Vayikra',
    'parshaquest-bamidbar': 'Sefer Bamidbar',
    'parshaquest-dvarim': 'Sefer Dvarim'
}

export default function TrialEnd({ game }) {
    return (
        <Box>
            <Text my={3}>The ParshaQuest Escape Room game is a fun and engaging group activity for the conclusion of {seferNames[game]}, and it serves as a complement to the ParshaQuest Activity Sheets from Bagels & Locks Studios. The activity sheets and game access are separate purchases.</Text>
            <Text my={3}>You can choose from three different versions of the ParshaQuest materials:</Text>
            <Box display="flex" flexDirection="row" my={3}>
                <Box display="flex" flexDir="column" justifyContent="space-between" alignItems="center" flex={1} px={2}>
                    <Text as="h3" fontWeight="bold">Version 1</Text>
                    <Text>All quotes from the text are in Hebrew</Text>
                    <Button as="a" target="_blank" href="https://bagelsandlocks.com/parsha/breisheet/sheet/hebrew" variantColor='darkBrown'>View Sample</Button>
                </Box>
                <Box display="flex" flexDir="column" justifyContent="space-between" alignItems="center" flex={1} px={2} borderLeft="1px solid gray">
                    <Text as="h3" fontWeight="bold">Version 2</Text>
                    <Text>All quotes are translated and Hebrew names are transliterated</Text>
                    <Button as="a" target="_blank" href="https://bagelsandlocks.com/parsha/breisheet/sheet/transliterated" variantColor='darkBrown'>View Sample</Button>
                </Box>
                <Box display="flex" flexDir="column" justifyContent="space-between" alignItems="center" flex={1} px={2} borderLeft="1px solid gray">
                    <Text as="h3" fontWeight="bold">Version 3</Text>
                    <Text>All quotes and names are in English</Text>
                    <Button as="a" target="_blank" href="https://bagelsandlocks.com/parsha/breisheet/sheet/translated" variantColor='darkBrown'>View Sample</Button>
                </Box>
            </Box>
        </Box>
    );
}
